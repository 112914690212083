import { useLocation, useHistory } from "react-router-dom";

function useSearchParamsV5() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const getSearchParams = () => {
    const paramsObject = {};
    searchParams.forEach((value, key) => {
      paramsObject[key] = value;
    });
    return paramsObject;
  };

  const setSearchParams = (updatedParams) => {
    const newSearchParams = new URLSearchParams();
    Object.entries(updatedParams).forEach(([key, value]) => {
      newSearchParams.set(key, value);
    });
    history.push({ search: newSearchParams.toString() });
  };

  return [getSearchParams(), setSearchParams];
}

export default useSearchParamsV5;
