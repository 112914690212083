import React, { useState } from "react";
import { Button } from "react-bootstrap";

export const appColors = {
  CHECKEDIN_COLOR: "#0b8043",
  banana: "#fbd75d",
  basil: "#51b749",
  blueberry: "#5484ed",
  flamingo: "#ff887c",
  grape: "#dbadff",
  graphite: "#e1e1e1",
  lavender: "#a4bdfc",
  peacock: "#46d6db",
  sage: "#7ae7bf",
  tangerine: "#ffb878",
  tomato: "#dc2127",
  blue: "#46d6db",
  waitingColor: "red",
  noshow: "#FFB6C1",
};

export const generateUID = () => {
  //  generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
};

export const getForamttedTime = (date) => {
  let hours = date.getHours();
  let suffix = hours >= 12 ? "PM" : "AM";
  let time =
    String(((hours + 11) % 12) + 1).padStart(2, "0") +
    ":" +
    String(date.getMinutes()).padStart(2, "0") +
    " " +
    suffix;
  return time;
};

export const getDate = (date) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    // Return null or a default value if date is invalid
    return null;
  }

  return (
    String(date?.getDate()).padStart(2, "0") +
    "/" +
    String(date?.getMonth() + 1).padStart(2, "0") +
    "/" +
    date?.getFullYear()
  );
};

export const getDay = (index) => {
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[index];
};
export const sortByDate = (appointments) => {
  return appointments
    .slice()
    .sort((a, b) => new Date(a.start) - new Date(b.start));
};

const CopyToClipBoardButton = (props) => {
  // This helper function expect textValue props
  // This copy the text to clipboard

  const copyIcon = <i className="fa fa-copy ml-2" aria-hidden="true"></i>;
  const checkIcon = (
    <i className="fa fa-check ml-2 helpers-check-icon" aria-hidden="true"></i>
  );
  const failIcon = (
    <i className="fa fa-times ml-2 helpers-fail-icon" aria-hidden="true"></i>
  );
  const [copied, setCopied] = useState(copyIcon);
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      await navigator.clipboard.writeText(props.textValue);
      setCopied(checkIcon);
      setLoading(false);
    } catch (err) {
      setCopied(failIcon);
      setLoading(false);
    }
  };

  return (
    // TODO: It's either we create button components or define our own classes
    <Button
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
      size="sm"
      className="w-unset py-0 px-1"
      variant={props.variant}
    >
      {copied}
    </Button>
  );
};

// Export reusable components
export { CopyToClipBoardButton };

export const success_options = {
  position: "bottom-right",
  style: {
    backgroundColor: "#28A745",
    color: "white",
    textAlign: "left",
    whiteSpace: "pre",
  },
};

export const error_options = {
  position: "bottom-right",
  style: {
    backgroundColor: "#DC3546",
    color: "white",
    textAlign: "left",
    whiteSpace: "pre",
  },
};
export const SNACK_DURATION = 5000;
export const PER_PAGE_COUNT = 20;
export const ERROR_MESSAGE =
  "The system encountered an error. Please contact the administrator";

const actionFunc = (search_params, action, param, value, resetName) => {
  switch (action) {
    case "get":
      let a = search_params.get(param);
      let val = "";
      if (a) {
        val = a.toString();
      }
      return val;
    case "set":
      search_params.set(param, value);
      if (resetName && resetName.length > 0) {
        for (var i = 0; i < resetName.length; i++) {
          search_params.delete(resetName[i]);
        }
      }
      let val1 = search_params.toString();
      return val1;
    case "delete":
      search_params.delete(param);
      let val2 = search_params.toString();
      return val2;
    default:
      return null;
  }
};

export const urlActions = (url, action, param, value) => {
  // console.log("url, action, param,value",url, action, param,value)
  var url1 = new URL(url);
  var query_string = url1 && url1.search ? url1.search : "";
  var search_params = new URLSearchParams(query_string);
  let actionResult = actionFunc(search_params, action, param, value);
  return actionResult;
};

export const paymentReason = {
  appointment: "Appointment",
  report: "Report",
  phone_call: "Phone Call",
  others: "Others",
};
export const getPaymentReasonLabel = (reason) => {
  return paymentReason[reason.toLowerCase()];
};

// TO TEST
// const PERMISSIONS={
//   "patients": ["list","add"],
//   "my_appointments":["list"],
//   "appointments":["list", "add"],
//   "waiting_list":  ["list",],
//   "payments":  ["list",],
//   "manaul_payment":  ["add"],
//   "settings":  ["list"],
// }

export const getPermissionForAction = (tab, action, all_permissions) => {
  var user_permissions = all_permissions
    ? all_permissions
    : localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(user_permissions);
  const PERMISSIONS = user_permissions;
  // console.log("PERMISSIONS",PERMISSIONS)
  if (PERMISSIONS && PERMISSIONS[tab] && PERMISSIONS[tab].length > 0) {
    if (PERMISSIONS[tab].includes(action)) {
      return true;
    }
    return false;
  }
  return false;
};

export const isUserStaff = () => {
  return true;
};

export const getValidDateStringForGraphQL = (value) => {
  return (
    value.getFullYear() +
    "-" +
    String(value.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(value.getDate()).padStart(2, "0")
  );
};

export const concatAllErrors = (graphQLErrors) => {
  console.log(graphQLErrors);
  let errorMsg = "";
  graphQLErrors?.map((item) => {
    errorMsg += item.message + ". ";
  });
  return errorMsg;
};

export const validateDecimal = (e) => {
  var t = e.target.value;
  if (t) {
    if (t.indexOf(".") >= 0) {
      var first = t.substr(0, t.indexOf("."));
      var second = t.substr(t.indexOf("."), 3);
      console.log(first, second);
      e.target.value = first + second;
    }
  }
};

export function checkRawDataAvailability(response) {
  // Check if lines object exists and has edges array
  if (
    response &&
    response.lines &&
    response.lines.edges &&
    response.lines.edges.length > 0
  ) {
    // Iterate over edges array
    for (const edge of response.lines.edges) {
      // Check if node object exists and rawData is not null
      if (edge.node && edge.node.rawData !== null) {
        return true; // If any rawData is not null, return true
      }
    }
  }
  return false; // If all rawData are null or response is empty, return false
}

export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`; // 'YYYY-MM-DD'
}

export const deepCopy = (obj) => {
  if (obj) {
    return JSON.parse(JSON.stringify(obj));
  }
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const removeEmpty = (obj) => {
  return Object.entries(obj)
    .filter(
      ([_, v]) =>
        v !== "" &&
        v !== null &&
        v !== undefined &&
        v !== "null" &&
        v !== "undefined"
    )
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};
