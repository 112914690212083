import { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "./Common/helpers.js";
import {
  isLoggedInVar,
  userDetailsVar,
  userPermissionsVar,
} from "./cache/cache";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";

const VerifyToken = (props) => {
  const token = localStorage.getItem("token");
  const [openSnackbar] = useSnackbar(error_options);
  const client = useApolloClient();

  const VERIFY_TOKEN = gql`
    mutation verifyToken($token: String!) {
      verifyToken(input: { token: $token }) {
        success
        errors
      }
    }
  `;

  const handleLogout = () => {
    const { history } = props;
    client.cache.evict({ fieldName: "me" });
    client.cache.gc();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    isLoggedInVar(false);
    userDetailsVar(null);
    userPermissionsVar(null);
    history.push("/");
  };

  const REQUEST_USER_PERMISSION = gql`
    query {
      userPermission
    }
  `;

  const [GetUserPermissions] = useLazyQuery(REQUEST_USER_PERMISSION, {
    fetchPolicy: "network-only",
    onCompleted({ userPermission }) {
      localStorage.setItem("user_permissions", JSON.stringify(userPermission));
      userPermissionsVar(JSON.stringify(userPermission));
    },
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.captureException("GetUserPermissions error " + err);

      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [verifyToken] = useMutation(VERIFY_TOKEN, {
    onCompleted({ verifyToken }) {
      if (!verifyToken.success) {
        let errors = verifyToken.errors.nonFieldErrors;
        for (let i in errors) {
          let error = errors[i];
          openSnackbar(error.message, [SNACK_DURATION]);
        }
        handleLogout();
      } else {
        GetUserPermissions();
      }
      if (verifyToken?.errors?.length > 0) {
        Sentry.captureException("verifyToken completed " + verifyToken?.errors);
      }
    },
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("verifyToken error" + err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    if (token) {
      verifyToken({ variables: { token: token } });
    }
  }, [token, verifyToken]);

  return null;
};

export default withRouter(VerifyToken);
