import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { cache } from "./cache/cache";
import "./styles.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter } from "react-router-dom";
import { createUploadLink } from "apollo-upload-client";

export const urlBackend = process.env.REACT_APP_BE_URL;

const enableSentry = true; // variable for sentry, true in master

export const httpLink = createUploadLink({
  uri: urlBackend + "graphql/",
  // TODO: Handle global error properly
  // https://www.apollographql.com/docs/react/data/error-handling/
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

if (enableSentry) {
  Sentry.init({
    dsn: "https://00b45350515d4ff9a329f22b8f44eb12@o346322.ingest.sentry.io/5601175",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    normalizeDepth: 10,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
