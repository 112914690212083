import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Table,
  Form,
  Button,
  Card,
  Dropdown,
  Container,
} from "react-bootstrap";
import Base from "./base.js";
import { withRouter, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";

import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  isUserStaff,
  concatAllErrors,
  checkRawDataAvailability,
} from "../Common/helpers";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import * as Sentry from "@sentry/browser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

function PatientPrescriptionHistory(props) {
  const isStaff = isUserStaff();
  const identifier = props.match.params.identifier;
  const [openSnackbar] = useSnackbar(error_options);
  const history = useHistory();
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [withRequest, setWithRequest] = useState(null);
  const requestId = props.match.params.requestid;

  const REQUEST_PATIENT = gql`
  query(
      $doctor_Identifier: String
      $start: Date
      $end: Date
      $withRequest: Boolean
  ) {
    patientPrescriptions(
      patient_Identifier:"${identifier}"
      doctor_Identifier: $doctor_Identifier
      start: $start
      end: $end
      withRequest: $withRequest
    ){
      totalCount
      edges{
        node{
          id
          created
          date
          doctor{
            id
            firstName
            lastName
          }
          patient{
            firstName
            lastName
            civilId
            identifier
            phone
            dob
            id
          }
          lines{
            edges{
              node{
                id
                medication
                quantity
                frequency
                route
                dosage
                rawData
                others
              }
            }
          }
        }
      }
    }
  }
`;

  const REQUEST_DOCTOR = gql`
    query {
      doctors(publicPractitioner: true) {
        edges {
          node {
            firstName
            lastName
            id
            email
            identifier
            doctorId
          }
        }
      }
    }
  `;

  const { data: doctors = [] } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("doctors error " + err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const handleDoctor = (event) => {
    const activeDoctor = doctors?.doctors?.edges?.find(
      (item) => item.node.identifier === event.target.value
    );
    setSelectedDoctor(activeDoctor);
  };

  const handleDateChange = (type, date) => {
    if (type === "start") {
      setStart(date);
    } else {
      setEnd(date);
    }
  };

  const DELETE_PRESCRIPTION_ITEM = gql`
    mutation deletePrescriptionItem($id: ID!) {
      deletePrescriptionItem(id: $id) {
        deleted
      }
    }
  `;

  const frequencyOptions = {
    once_daily: "Once daily",
    twice_daily: "Twice daily",
    three_times_a_day: "Thrice a day",
    four_times_a_days: "Four times a days",
    others: "Others",
  };

  const routeOptions = {
    per_oral: "PO",
    iv: "IV",
    im: "IM",
    id: "ID",
    nasal: "Nasal Spray",
  };

  const quantityOptions = {
    one: "1 Month",
    two: "2 Month",
    three: "3 Month",
    four: "4 Month",
    five: "5 Month",
    six: "6 Month",
    seven: "7 Month",
    eight: "8 Month",
    nine: "9 Month",
    ten: "10 Month",
    eleven: "11 Month",
    twelve: "12 Month",
  };

  const [deletePrescriptionItem] = useMutation(DELETE_PRESCRIPTION_ITEM, {
    onCompleted({ deletePrescriptionItem }) {
      if (deletePrescriptionItem) {
        console.log("deletePrescriptionItem", deletePrescriptionItem);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("deletePrescriptionItem error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
    refetchQueries: [{ query: REQUEST_PATIENT, fetchPolicy: "network-only" }],
  });

  const [getPatientPrescriptions, { data, loading }] = useLazyQuery(
    REQUEST_PATIENT,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        Sentry.captureException("REQUEST_PATIENT error " + e);

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  useEffect(() => {
    const payload = {
      doctor_Identifier: selectedDoctor?.node?.doctorId
        ? selectedDoctor?.node?.identifier
        : "",
      start: start ? moment(start).format("YYYY-MM-DD") : null,
      end: end ? moment(end).format("YYYY-MM-DD") : null,
    };
    if (withRequest) {
      payload.withRequest = withRequest === "true" ? true : false;
    }

    getPatientPrescriptions({ variables: payload });
  }, [
    end,
    getPatientPrescriptions,
    selectedDoctor?.node?.doctorId,
    selectedDoctor?.node?.identifier,
    start,
    withRequest,
  ]);

  const patient_prescriptions = data?.patientPrescriptions;

  const deletePrescription = (prescItem) => {
    let variables = {
      id: prescItem.node.id,
    };
    if (window.confirm("Are you sure you want to delete this prescription?")) {
      deletePrescriptionItem({ variables });
    }
  };

  const reprintData = (node) => {
    let uri = "/prescription/print";
    if (requestId) {
      uri = `/prescription/print/${requestId}`;
    }

    history.push({
      pathname: uri,
      state: { reprintData: node },
    });
  };

  const renderActionButtons = (node) => {
    console.log(checkRawDataAvailability(node));
    const refill = checkRawDataAvailability(node);

    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary">Actions</Dropdown.Toggle>
        <Dropdown.Menu>
          {refill ? (
            <Dropdown.Item onClick={() => reprintData(node)}>
              {requestId ? "Use for request" : "Refill"}
            </Dropdown.Item>
          ) : null}

          <Dropdown.Item href={`/prescription/details/${node.id}`}>
            Details
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getPrescriptionCard = (item, index) => {
    if (item?.node?.lines?.edges.length > 0) {
      return (
        <div key={index}>
          <Card className="col-md-10 mt-1">
            <Card.Body>
              <Card.Title className="d-flex flex-row justify-content-between align-items-center my-4">
                <div>
                  Date :{" "}
                  <b>
                    {item.node.date
                      ? getDate(new Date(item.node.date))
                      : getDate(new Date(item.node.created))}
                  </b>
                </div>
                <div>
                  Prescribed By :{" "}
                  <b>
                    {item.node.doctor.firstName} {item.node.doctor.lastName}
                  </b>
                </div>
                <div>{renderActionButtons(item.node)}</div>
              </Card.Title>

              <Table responsive className=" ">
                <thead>
                  <tr>
                    <th scope="col " className="text-uppercase">
                      Medication
                    </th>
                    <th scope="col " className="text-uppercase">
                      Dosage
                    </th>
                    <th scope="col " className="text-uppercase">
                      Frequency
                    </th>
                    <th scope="col " className="text-uppercase">
                      Route
                    </th>
                    <th scope="col " className="text-uppercase">
                      Quantity
                    </th>
                    <th scope="col " className="text-uppercase text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                {/* {loading ? <Preloader /> : */}
                <tbody>
                  {item?.node?.lines?.edges.map((i, index) => {
                    let freq = frequencyOptions[i.node.frequency];

                    if (i.node.frequency === "others") {
                      freq = i.node.others;
                    }

                    return (
                      <tr key={index} className="patient_table_row">
                        <td>{i.node.medication}</td>
                        <td>{i.node.dosage}</td>
                        <td>{freq}</td>
                        <td>{routeOptions[i.node.route]}</td>
                        <td>{quantityOptions[i.node.quantity]}</td>
                        <td>
                          <Button
                            variant="link"
                            size="sm"
                            className="m-0 w-100"
                            onClick={() => deletePrescription(i)}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* } */}
              </Table>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  return (
    <Base
      isPatientPortal={!isStaff}
      title={<b>Prescription History</b>}
      showHeader={true}
    >
      <Container fluid>
        <Row className="mb-2">
          <Col>
            <Form.Group as={Row}>
              <Form.Label column md={3}>
                Practitioner
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="doctor"
                  value={selectedDoctor ? selectedDoctor.node.identifier : null}
                  onChange={handleDoctor}
                  column
                  md={8}
                >
                  <option value="">Select Practitioner</option>
                  {doctors &&
                    doctors?.doctors?.edges?.map((doctor) => {
                      return (
                        <option
                          value={doctor.node.identifier}
                          key={doctor.node.id}
                        >
                          {doctor.node.firstName} {doctor.node.lastName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Label>
              <Form.Label column md={3}>
                With request
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="withRequest"
                  value={withRequest}
                  onChange={(event) => setWithRequest(event.target.value)}
                  column
                  md={8}
                >
                  <option value="">Select</option>
                  <option value={"true"}>With request</option>
                  <option value={"false"}>Without request</option>
                </Form.Control>
              </Form.Label>
              <Form.Label column md={3}>
                Start
                <DatePicker
                  selected={start ? start : ""}
                  onChange={(date) => handleDateChange("start", date)}
                  name="start"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                />
              </Form.Label>
              <Form.Label column md={3}>
                End
                <DatePicker
                  selected={end ? end : ""}
                  onChange={(date) => handleDateChange("end", date)}
                  name="end"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                />
              </Form.Label>

              <Form.Label column md={3}>
                Total number
                <h4>
                  <strong>{patient_prescriptions?.totalCount || 0}</strong>
                </h4>
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
      </Container>
      {patient_prescriptions?.edges?.map((item, index) => {
        return getPrescriptionCard(item, index);
      })}
    </Base>
  );
}
export default withRouter(PatientPrescriptionHistory);
