import React, { useEffect, useRef, useState, useCallback } from "react";
import style from "./CreateEventForm.module.css";
import {
  Button,
  Form,
  Col,
  Row,
  Container,
  Spinner,
  Alert,
  Table,
  Modal,
} from "react-bootstrap";
import { Formik } from "formik";
import {
  dataPatientInVar,
  dateEventInVar,
  calendarActiveMonthVar,
  isCustomAppVar,
  editableAppointmentVar,
  appFromCalVar,
  displayLoaderVar,
} from "../../../cache/cache";
import {
  getForamttedTime,
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
  validateDecimal,
  formatDate,
  getDate,
  deepCopy,
} from "../../../Common/helpers.js";
import SelectPatientModal from "../../../Views/SearchForPatient/selectPatientModal";
import DatePicker from "react-datepicker";
import { useSnackbar } from "react-simple-snackbar";
import { gql, useQuery, useMutation, useReactiveVar } from "@apollo/client";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";

const CreateEventForm = (props) => {
  const {
    createEvent,
    doctorList,
    patientDataOfSearchPage,
    selectedDateEvent,
    selectedDoctor,
    setSelectedDoctor,
    doctorLeaves,
    fullCalendarRef,
    setSelectedSession,
    setSelectedPatient,
    selectedPatient,
    selectedSession,
    waitingList,
    calendarData,
    formikRef,
    GetEvents,
    history,
    getWaitingList,
  } = props;
  const searchPatientRef = useRef();
  const [selectedDate, setSelectedDate] = useState([]);
  const [chooseNextIndex, setChooseNextIndex] = useState(0);
  const [shouldBeSeen, setShouldBeSeen] = useState(null);
  const [lastSeenDate, setLastSeenDate] = useState(null);
  const [initialDate, setinitialDate] = useState(null);
  const [calledDate, setCalledDate] = useState(null);
  const [waitinglistId, setWaitinglistId] = useState(null);

  const [lastDate, setlastDate] = useState(null);

  const [showWaitingList, setShowWaitingList] = useState(false);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [openSnackbar] = useSnackbar(error_options);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeWaitingList, setActiveWaitingList] = useState(null);
  const [show, setShow] = useState(false);

  const paymentCutOffTime = 72;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var isCustomApp = useReactiveVar(isCustomAppVar);
  if (!isCustomApp) {
    isCustomApp =
      window.location.href.indexOf("isCustom=true") > -1 ? true : false;
  }
  const editableAppointment = useReactiveVar(editableAppointmentVar);
  const appFromCal = useReactiveVar(appFromCalVar);
  const appCreateFromCal = props?.location?.state?.appCreateFromCal;

  const DELETE_WAITING_RECORD = gql`
    mutation deleteWaitinglistRecord($id: ID!) {
      deleteWaitinglistRecord(id: $id) {
        deleted
      }
    }
  `;

  const [deleteWaitingRecord] = useMutation(DELETE_WAITING_RECORD, {
    onCompleted: ({ deleteWaitinglistRecord }) => {
      if (deleteWaitinglistRecord?.deleted) {
        openSnackbarSuccess("Deleted Waiting Record", [SNACK_DURATION]);
        selectedPatient.waitinglist.edges =
          selectedPatient?.waitinglist?.edges.filter(
            (edge) => edge.node.id !== waitinglistId
          );
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("deleteWaitingRecord error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const deleteWaitingRecordClicked = (id) => {
    if (window.confirm("Are you sure to delete this waiting list record?")) {
      setWaitinglistId(id);
      deleteWaitingRecord({
        variables: { id: id },
      });
    }
  };

  const REQUEST_SETTINGS = gql`
    query {
      settings
    }
  `;

  // GET leave detail
  const { data: settings_obj = null } = useQuery(REQUEST_SETTINGS, {
    fetchPolicy: "network-only",
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.captureException("REQUEST_SETTINGS Completed " + err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const CREATE_CUSTOM_EVENT = gql`
    mutation createEvent(
      $doctor: ID!
      $start: DateTime!
      $title: String!
      $requirePayment: Boolean!
      $description: String!
      $doctorEventType: ID!
    ) {
      createEvent(
        input: {
          doctor: $doctor
          start: $start
          title: $title
          requirePayment: $requirePayment
          description: $description
          doctorEventType: $doctorEventType
        }
      ) {
        errors {
          field
          messages
        }
        obj {
          start
          end
          title
          description
          id
          # find a session type and show it instead. change toast in createEventFromHoc as well
          eventType {
            title
          }
          patient {
            id
            identifier
            firstName
            lastName
          }
          doctor {
            id
            firstName
            lastName
            identifier
          }
        }
      }
    }
  `;

  const UPDATE_APPOINTMENT = gql`
    mutation updateEvent(
      $id: ID!
      $title: String!
      $start: DateTime!
      $description: String!
      $doctorEventType: ID!
      $doctor: ID!
      $patient: ID
      $require_payment: Boolean
      $discount: Float
      $waitinglist: ID
      $shouldBeSeen: DateTime
      $lastSeenDate: Date
      $initialDate: Date
      $lastDate: Date
      $calledDate: Date
      $waitingListNotes: String
    ) {
      updateEvent(
        input: {
          id: $id
          title: $title
          start: $start
          description: $description
          doctorEventType: $doctorEventType
          doctor: $doctor
          patient: $patient
          requirePayment: $require_payment
          discount: $discount
          waitinglist: $waitinglist
          shouldBeSeen: $shouldBeSeen
          lastSeenDate: $lastSeenDate
          initialDate: $initialDate
          lastDate: $lastDate
          calledDate: $calledDate
          waitingListNotes: $waitingListNotes
        }
      ) {
        errors {
          field
          messages
        }
        obj {
          start
          end
          title
          description
          id
          eventType {
            title
            id
          }
          location {
            title
            id
          }
          doctor {
            id
            firstName
            lastName
            identifier
          }
          duration
          price
        }
      }
    }
  `;

  const [createCustomEvent] = useMutation(CREATE_CUSTOM_EVENT, {
    onCompleted: ({ createEvent }) => {
      if (createEvent?.errors?.length === 0) {
        let alert_msg = (
          <span>
            {createEvent?.obj?.eventType?.title} created for{" "}
            <strong>
              {createEvent.obj.doctor.firstName +
                " " +
                createEvent.obj.doctor.lastName}
            </strong>
            <br />
          </span>
        );
        // let patient_url = '/patient/record/' +createEvent.obj.patient.identifier;
        // history.push('/appointments');
        let docParams = "";
        if (createEvent?.obj?.doctor?.identifier) {
          docParams = "&&doctor=" + createEvent?.obj?.doctor?.identifier;
        }
        history.push({
          pathname: "/appointments",
          search:
            "?calendarInitDate=" +
            createEvent.obj.start.split("+")[0] +
            "&&calendarInitView=timeGridDay" +
            docParams,
        });
        openSnackbarSuccess(alert_msg, [SNACK_DURATION]);
        // if(formikRef && formikRef.current){
        //   formikRef.current.handleReset();
        // }
        // setSelectedPatient([]);
        // dataPatientInVar([]);
        // dateEventInVar([]);
      } else {
        var alert_msg = "";
        createEvent?.errors?.map((error) => {
          let messagesArr = error.messages;
          if (messagesArr) {
            alert_msg += messagesArr.join(" ");
          }
          return null;
        });
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2000);
        openSnackbar(alert_msg, [SNACK_DURATION]);
      }
    },
    onError: (error) => {
      Sentry.setContext("error", error?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: error });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: { code: error?.networkError?.statusCode },
      });
      Sentry.captureException("createCustomEvent error " + error);
      let errorMsg = concatAllErrors(error?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
      setIsSubmitting(false);
    },
  });

  const [updateEvent] = useMutation(UPDATE_APPOINTMENT, {
    onCompleted: ({ updateEvent }) => {
      if (updateEvent?.errors?.length > 0) {
        let errors = updateEvent.errors;

        let messages = "";

        for (const item of errors) {
          let eMessages = item.messages;

          for (const m of eMessages) {
            if (messages) {
              messages = messages + ", " + m;
            } else {
              messages = m;
            }
          }
        }

        Sentry.captureException("updateEvent Completed " + updateEvent?.errors);
        openSnackbar(messages, [SNACK_DURATION]);
        setIsSubmitting(false);
      } else {
        openSnackbarSuccess("Appointment Updated", [SNACK_DURATION]);
        // history.push('/appointments');
        let docParams = "";
        if (updateEvent?.obj?.doctor?.identifier) {
          docParams = "&&doctor=" + updateEvent?.obj?.doctor?.identifier;
        }
        history.push({
          pathname: "/appointments",
          search:
            "?calendarInitDate=" +
            updateEvent.obj.start.split("+")[0] +
            "&&calendarInitView=timeGridDay" +
            docParams,
        });
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2000);
      }
    },
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("updateEvent error " + err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);

      setIsSubmitting(false);
    },
  });

  // ON NEXT AVAILABLE APPOINTMENT BUTTON CLICK
  const onChoseAppointment = () => {
    let nextIndex = chooseNextIndex;
    let allAvailableEvents =
      calendarData && calendarData.calendarSlots
        ? calendarData.calendarSlots.allFilters
        : [];
    let editIdObj = {};
    if (editableAppointment?.eventId) {
      editIdObj = { excludeId: editableAppointment.eventId };
    }
    if (allAvailableEvents && allAvailableEvents.length > 0) {
      if (nextIndex < allAvailableEvents.length) {
        if (
          selectedDateEvent &&
          selectedDateEvent.dateEvent &&
          selectedDateEvent.dateEvent.start
        ) {
          let current_date = selectedDateEvent.dateEvent.start;
          let current_index = allAvailableEvents.findIndex((i) => {
            let slotStart = new Date(i.start).toString();
            let currentStart = new Date(current_date).toString();
            if (slotStart === currentStart) {
              return true;
            }
            return false;
          });
          if (current_index) {
            nextIndex = current_index + 1;
          }
        }
        let nextAvailable = allAvailableEvents[nextIndex];
        if (nextAvailable && nextAvailable.start) {
          if (fullCalendarRef && fullCalendarRef.current) {
            setTimeout(() => {
              fullCalendarRef?.current
                ?.getApi()
                ?.changeView("timeGridDay", nextAvailable.start);
            }, 200);
          }
          dateEventInVar({
            start: nextAvailable.start,
            end: nextAvailable.end,
          });
          let newIndex = nextIndex + 1;
          setChooseNextIndex(newIndex);
        }
      } else {
        let slotsUntil = calendarData.calendarSlots.slotsUntil;
        let current_month = slotsUntil.getMonth();
        let current_year = slotsUntil.getFullYear();
        let dateStart = new Date(current_year, current_month, 1);
        let dateEnd = new Date(current_year, current_month + 1, 1);
        setChooseNextIndex(0);
        calendarActiveMonthVar(current_month);
        // dateEnd.setHours(23);
        if (dateStart && dateEnd && selectedDoctor?.node?.identifier) {
          displayLoaderVar(true);
          GetEvents({
            variables: {
              doctor_identifier: selectedDoctor.node.identifier,
              start: dateStart,
              end: dateEnd,
              trigger: "nextAppointmentMonth",
              nextMonth: true,
              ...editIdObj,
            },
          });
        }
      }
    } else if (
      selectedDoctor &&
      selectedDoctor.node &&
      calendarData.calendarSlots
    ) {
      let slotsUntil = calendarData.calendarSlots.slotsUntil;
      let current_month = slotsUntil.getMonth();
      let current_year = slotsUntil.getFullYear();
      calendarActiveMonthVar(current_month);
      let dateStart = new Date(current_year, current_month, 1);
      let dateEnd = new Date(current_year, current_month + 1, 1);
      setChooseNextIndex(0);
      // dateEnd.setHours(23);
      if (dateStart && dateEnd && selectedDoctor?.node?.identifier) {
        displayLoaderVar(true);
        GetEvents({
          variables: {
            doctor_identifier: selectedDoctor.node.identifier,
            start: dateStart,
            end: dateEnd,
            trigger: "nextAppointmentMonth",
            nextMonth: true,
            ...editIdObj,
          },
        });
      }
    }
    localStorage.setItem("calendarViewStored", "timeGridDay");
  };

  const handleDoctor = (event) => {
    formikRef.current.setFieldValue("doctor", event.target.value);
    let act_doc = eventData.doctors.edges.find(
      (i) => i.node.identifier === event.target.value
    );
    setSelectedDoctor(act_doc);
    if (act_doc && act_doc?.node?.identifier) {
      doctorLeaves({
        variables: { doctor_identifier: act_doc.node.identifier },
      });
    }
    formikRef.current.setFieldValue("session", "");
    setSelectedSession(null);
    dateEventInVar([]);

    if (getWaitingList) {
      getWaitingList({
        variables: { doctor_identifier: act_doc.node.identifier },
      });
    }

    // update the discount on change of doctor
    let patient_doctor_discounts = selectedPatient.patientDoctorDiscount;

    let selected_patient_doctor_discount = patient_doctor_discounts?.edges.find(
      (i) => i.node.doctor.identifier === act_doc.node.identifier
    );

    if (selected_patient_doctor_discount) {
      if (parseFloat(selected_patient_doctor_discount.node.discount) > 0) {
        formikRef.current.setFieldValue(
          "discount_value",
          selected_patient_doctor_discount.node.discount
        );
        formikRef.current.setFieldValue("show_discount", true);
        setShowDiscount(true);
      }
    } else if (parseFloat(selectedPatient.discount) > 0) {
      // check patient discount
      formikRef.current.setFieldValue(
        "discount_value",
        selectedPatient.discount
      );
      formikRef.current.setFieldValue("show_discount", true);
      setShowDiscount(true);
    } else if (parseFloat(formikRef.current.values.discount_value) > 0) {
      formikRef.current.setFieldValue("discount_value", 0);
      formikRef.current.setFieldValue("show_discount", false);
      setShowDiscount(false);
    }
  };

  const handleSession = (event) => {
    formikRef.current.setFieldValue("session", event.target.value);
    let session_obj = selectedDoctor?.node?.eventType?.edges?.find(
      (i) => i.node.id === event.target.value
    );
    setSelectedSession(session_obj);
    dateEventInVar([]);
  };

  const onSelectPatient = (patientData) => {
    setSelectedPatient(deepCopy(patientData));
    dataPatientInVar(patientData);
  };

  const onCancelForm = (handleReset) => {
    handleReset();
    setSelectedPatient([]);
    setSelectedDoctor(null);
    dataPatientInVar([]);
    dateEventInVar([]);
    appFromCalVar(null);
    setTimeout(() => {
      history.goBack();
    }, 200);
  };

  useEffect(() => {
    if (patientDataOfSearchPage && patientDataOfSearchPage?.dataPatient) {
      setSelectedPatient(deepCopy(patientDataOfSearchPage.dataPatient));

      let patient_doctor_discount_used = false;

      if (patientDataOfSearchPage.dataPatient.doctor && !selectedDoctor) {
        let patient_doctor_identifier =
          patientDataOfSearchPage.dataPatient?.doctor?.identifier;
        let patient_doctor = doctorList?.doctors?.edges.find(
          (i) => i.node.identifier === patient_doctor_identifier
        );
        if (patient_doctor) {
          setSelectedDoctor(patient_doctor);
          if (formikRef && formikRef.current) {
            formikRef.current.setFieldValue(
              "doctor",
              patient_doctor?.node?.identifier
            );

            if (getWaitingList) {
              getWaitingList({
                variables: {
                  doctor_identifier: patient_doctor.node.identifier,
                },
              });
            }
          }
          if (patient_doctor?.node?.identifier) {
            doctorLeaves({
              variables: { doctor_identifier: patient_doctor.node.identifier },
            });
          }

          // Since there was a doctor check under patientDoctorDiscount if there was a doctor
          let patient_doctor_discounts =
            patientDataOfSearchPage.dataPatient?.patientDoctorDiscount;

          let selected_patient_doctor_discount =
            patient_doctor_discounts?.edges.find(
              (i) => i.node.doctor.identifier === patient_doctor.node.identifier
            );

          if (selected_patient_doctor_discount) {
            if (
              parseFloat(selected_patient_doctor_discount.node.discount) > 0 &&
              parseFloat(formikRef.current.values.discount_value) === 0
            ) {
              formikRef.current.setFieldValue(
                "discount_value",
                selected_patient_doctor_discount.node.discount
              );
              formikRef.current.setFieldValue("show_discount", true);
              setShowDiscount(true);

              patient_doctor_discount_used = true;
            }
          }
        }
      }

      if (
        !patient_doctor_discount_used &&
        patientDataOfSearchPage.dataPatient.discount &&
        parseFloat(formikRef.current.values.discount_value) === 0
      ) {
        formikRef.current.setFieldValue(
          "discount_value",
          patientDataOfSearchPage.dataPatient.discount
        );
        if (patientDataOfSearchPage.dataPatient.discount > 0) {
          formikRef.current.setFieldValue("show_discount", true);
          setShowDiscount(true);
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDataOfSearchPage, setSelectedPatient, selectedDoctor]);

  useEffect(() => {
    if (selectedPatient.id) {
      formikRef.current.setFieldValue(
        "patient",
        `${selectedPatient.firstName} ${selectedPatient.lastName} (${selectedPatient.identifier})`
      );
    }
  }, [selectedPatient, formikRef]);

  useEffect(() => {
    if (appFromCal && appCreateFromCal) {
      //appCreateFromCal boolean to check if this variable can be used or not
      if (appFromCal?.patientData) {
        setSelectedPatient(deepCopy(appFromCal?.patientData));
        formikRef.current.setFieldValue(
          "patient",
          `${appFromCal?.patientData?.firstName} ${appFromCal?.patientData?.lastName} (${appFromCal?.patientData?.identifier})`
        );
      }

      if (appFromCal.doctor && appFromCal?.doctor?.node?.identifier) {
        formikRef.current.setFieldValue(
          "doctor",
          appFromCal.doctor.node.identifier
        );

        if (getWaitingList) {
          getWaitingList({
            variables: { doctor_identifier: appFromCal.doctor.node.identifier },
          });
        }

        setSelectedDoctor(appFromCal.doctor);
        doctorLeaves({
          variables: { doctor_identifier: appFromCal.doctor.node.identifier },
        });
      }
      if (appFromCal.selectedDateStr && fullCalendarRef.current) {
        let dd = String(appFromCal.selectedDateStr.getDate()).padStart(2, "0");
        let mm = String(appFromCal.selectedDateStr.getMonth() + 1).padStart(
          2,
          "0"
        );
        let yy = appFromCal.selectedDateStr.getFullYear();
        let time =
          "T" +
          String(appFromCal.selectedDateStr.getHours()).padStart(2, "0") +
          ":" +
          String(appFromCal.selectedDateStr.getMinutes()).padStart(2, "0") +
          ":00+03:00";
        let calDate = yy + "-" + mm + "-" + dd + time;
        // calDate="2022-01-11T15:00:00+03:00";
        fullCalendarRef?.current?.getApi()?.changeView("timeGridDay", calDate);
      }
    }
  }, [appFromCal, fullCalendarRef]);

  useEffect(() => {
    if (editableAppointment) {
      formikRef.current.setFieldValue(
        "patient",
        `${editableAppointment?.patient?.firstName} ${editableAppointment?.patient?.lastName} (${editableAppointment?.patient?.identifier})`
      );
      formikRef.current.setFieldValue(
        "doctor",
        editableAppointment.doctor.identifier
      );

      if (getWaitingList) {
        getWaitingList({
          variables: {
            doctor_identifier: editableAppointment.doctor.identifier,
          },
        });
      }

      formikRef.current.setFieldValue(
        "descriptionEn",
        editableAppointment.description
      );
      if (editableAppointment?.waitinglist?.waitingList?.id) {
        formikRef.current.setFieldValue(
          "waiting_list",
          editableAppointment.waitinglist?.waitingList?.id
        );
        setShowWaitingList(true);
        setActiveWaitingList(editableAppointment?.waitinglist?.waitingList);
      }
      if (editableAppointment.title) {
        formikRef.current.setFieldValue("title", editableAppointment.title);
      }
      if (editableAppointment.discount) {
        formikRef.current.setFieldValue(
          "discount_value",
          editableAppointment.discount
        );
        setShowDiscount(true);
      }
      if (editableAppointment.shouldBeSeen) {
        setShouldBeSeen(new Date(editableAppointment.shouldBeSeen));
      }
      if (editableAppointment.lastSeenDate) {
        setLastSeenDate(new Date(editableAppointment.lastSeenDate));
      }
      if (editableAppointment.initialDate) {
        setinitialDate(new Date(editableAppointment.initialDate));
      }
      if (editableAppointment?.waitinglist?.calledDate) {
        setCalledDate(new Date(editableAppointment.waitinglist.calledDate));
      }

      if (editableAppointment?.waitinglist?.waitingListNotes) {
        formikRef.current.setFieldValue(
          "waitingListNotes",
          editableAppointment.waitinglist.waitingListNotes
        );
      }

      if (editableAppointment.lastDate) {
        setlastDate(new Date(editableAppointment.lastDate));
      }
      formikRef.current.setFieldValue(
        "require_payment",
        editableAppointment.requirePayment
      );
      // formikRef.current.setFieldValue('shouldBeSeen', editableAppointment.shouldBeSeen);
      setSelectedDoctor({ node: editableAppointment.doctor });
      formikRef.current.setFieldValue(
        "session",
        editableAppointment.doctorEventType.id
      );
      let session_obj = editableAppointment?.doctor?.eventType?.edges?.find(
        (i) => i.node.id === editableAppointment.doctorEventType.id
      );
      setSelectedSession(session_obj);
      doctorLeaves({
        variables: {
          doctor_identifier: editableAppointment?.doctor?.identifier,
        },
      });
    }
    if (editableAppointment) {
      let eAppEnd = new Date(editableAppointment.end);
      eAppEnd.setMinutes(eAppEnd.getMinutes());
      let dd = String(eAppEnd.getDate()).padStart(2, "0");
      let mm = String(eAppEnd.getMonth() + 1).padStart(2, "0");
      let yy = eAppEnd.getFullYear();
      let time =
        "T" +
        String(eAppEnd.getHours()).padStart(2, "0") +
        ":" +
        String(eAppEnd.getMinutes()).padStart(2, "0");
      let eAppEndStr = yy + "-" + mm + "-" + dd + time;
      dateEventInVar({
        start: editableAppointment.start,
        end: eAppEndStr,
      });
      fullCalendarRef?.current
        ?.getApi()
        ?.changeView("timeGridWeek", editableAppointment.start);
    }
  }, [editableAppointment, formikRef]);

  useEffect(() => {
    return function cleanup() {
      isCustomAppVar(false);
      editableAppointmentVar(null);
    };
  }, []);

  const eventData = {
    doctors: doctorList?.doctors,
    listEventLocation: [],
    listEventType: [],
  };

  const onSubmit = (values, { resetForm }) => {
    setIsSubmitting(true);
    if (isCustomApp && editableAppointment) {
      let val = {
        id: editableAppointment.id,
        title: values.title,
        description: values.descriptionEn
          ? values.descriptionEn
          : `${editableAppointment?.patient?.firstName} ${editableAppointment?.patient?.lastName}`,
        start: selectedDate.start,
        doctor: selectedDoctor?.node?.id
          ? selectedDoctor?.node?.id
          : editableAppointment.doctor.id,
        doctorEventType: values.session
          ? values.session
          : editableAppointment?.doctorEventType?.id,
      };
      updateEvent({
        variables: val,
      });
      return;
    }

    if (isCustomApp) {
      let val = {
        doctor: selectedDoctor.node.id,
        start: selectedDate.start,
        title: values.title,
        requirePayment: false,
        description: "no description",
        doctorEventType: values.session,
      };
      createCustomEvent({
        variables: val,
      });
    } else {
      if (editableAppointment) {
        let val = {
          id: editableAppointment.id,
          title: editableAppointment.title,
          description: values.descriptionEn
            ? values.descriptionEn
            : `${editableAppointment?.patient?.firstName} ${editableAppointment?.patient?.lastName}`,
          start: selectedDate.start,
          doctorEventType: values.session
            ? values.session
            : editableAppointment?.doctorEventType?.id,
          patient: selectedPatient?.id
            ? selectedPatient.id
            : editableAppointment?.patient?.id,
          doctor: selectedDoctor?.node?.id
            ? selectedDoctor?.node?.id
            : editableAppointment.doctor.id,
          require_payment: values.require_payment,
        };

        if (showWaitingList) {
          val["waitinglist"] = values.waiting_list;
          val["waitingListNotes"] = values.waitingListNotes;
          if (shouldBeSeen) {
            val["shouldBeSeen"] = formatDate(shouldBeSeen);
          }
          if (lastSeenDate) {
            val["lastSeenDate"] = formatDate(lastSeenDate);
          }
          if (initialDate) {
            val["initialDate"] = formatDate(initialDate);
          }
          if (calledDate) {
            val["calledDate"] = formatDate(calledDate);
          }
          if (lastDate) {
            val["lastDate"] = formatDate(lastDate);
          }
        }
        if (showDiscount) {
          val["discount"] = values.discount_value;
        }

        if (values.require_payment) {
          // let paymentCutOffTime =
          //   selectedSession?.node.notification?.downPaymentNotificationCutoff;
          // if (!paymentCutOffTime) {
          //   paymentCutOffTime = settings_obj?.settings?.downpayment_cutoff_time;
          // }
          if (paymentCutOffTime) {
            let currentTime = new Date();
            currentTime.setHours(currentTime.getHours() + paymentCutOffTime);
            currentTime.setMinutes(currentTime.getMinutes() + 30);
            let proposedAppDate = new Date(selectedDate.start);
            if (proposedAppDate > currentTime) {
              updateEvent({
                variables: val,
              });
            } else {
              alert(
                "You are about to book an appointment that will be cancelled due to payment cut off time. Remove required payment to continue booking an appointment"
              );
            }
          }
        } else {
          updateEvent({
            variables: val,
          });
        }
      } else {
        let val = {
          patient: selectedPatient.id,
          doctor: selectedDoctor.node.id,
          start: selectedDate.start,
          title: `${selectedPatient.firstName} ${selectedPatient.lastName}`,
          doctorEventType: values.session,
          description: values.descriptionEn
            ? values.descriptionEn
            : `${selectedPatient.firstName} ${selectedPatient.lastName}`,
          require_payment: values.require_payment,
        };

        if (showWaitingList) {
          val["waitinglist"] = values.waiting_list;
          val["waitingListNotes"] = values.waitingListNotes;

          if (shouldBeSeen) {
            val["shouldBeSeen"] = formatDate(shouldBeSeen);
          }
          if (lastSeenDate) {
            val["lastSeenDate"] = formatDate(lastSeenDate);
          }
          if (initialDate) {
            val["initialDate"] = formatDate(initialDate);
          }
          if (calledDate) {
            val["calledDate"] = formatDate(calledDate);
          }
          if (lastDate) {
            val["lastDate"] = formatDate(lastDate);
          }
        }
        if (showDiscount) {
          val["discount"] = values.discount_value;
        }
        // new condition added to alert user of the downpayment cut off
        if (values.require_payment) {
          // let paymentCutOffTime =
          //   selectedSession?.node.notification?.downPaymentNotificationCutoff;
          // if (!paymentCutOffTime) {
          //   paymentCutOffTime = settings_obj?.settings?.downpayment_cutoff_time;
          // }
          if (paymentCutOffTime) {
            let currentTime = new Date();
            currentTime.setHours(currentTime.getHours() + paymentCutOffTime);
            currentTime.setMinutes(currentTime.getMinutes() + 30); // allowing 30 mins extra time for the patients to pay
            let proposedAppDate = new Date(selectedDate.start);
            if (proposedAppDate > currentTime) {
              createEvent({
                variables: val,
              });
            } else {
              alert(
                "You are about to book an appointment that will be cancelled due to payment cut off time. Remove required payment to continue booking an appointment"
              );
            }
          }
        } else {
          createEvent({
            variables: val,
          });
        }
        // new condition added to alert user of the downpayment cut off
      }
    }
  };
  const initialValues = {
    doctor: "",
    patient: "",
    start: "",
    date: "",
    title: "",
    descriptionEn: "",
    eventType: "",
    session: "",
    discount_value: "0",
    show_discount: false,
    require_payment: true,
    should_be_seen: "",
    initialDate: "",
    lastDate: "",
    lastSeenDate: "",
    calledDate: "",
    waitingListNotes: "",
  };

  const formatEventDate = useCallback(
    (selectedDateEvent) => {
      let eventDate_st = new Date(selectedDateEvent?.start);
      let eventDate_en = new Date(selectedDateEvent?.end);

      let time_st = getForamttedTime(eventDate_st);
      let time_en = getForamttedTime(eventDate_en);

      let date = eventDate_st.toLocaleString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
      formikRef.current.setFieldValue("start", time_st + " - " + time_en);
      formikRef.current.setFieldValue("date", date);
    },
    [formikRef]
  );

  useEffect(() => {
    if (selectedDateEvent.dateEvent.start && formikRef && formikRef.current) {
      setSelectedDate(selectedDateEvent.dateEvent);
      formatEventDate(selectedDateEvent.dateEvent, formikRef);
    } else if (formikRef && formikRef.current) {
      formikRef.current.setFieldValue("date", "");
      formikRef.current.setFieldValue("start", "");
      setChooseNextIndex(0);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateEvent, formikRef]);

  const isSubmitDisabled = (formikFormValues) => {
    if (isCustomApp && window.location.href.indexOf("isCustom=true") > -1) {
      if (
        formikFormValues &&
        formikFormValues.doctor &&
        formikFormValues.title &&
        formikFormValues.session &&
        selectedDateEvent.dateEvent.start
      ) {
        return false;
      }
      return true;
    } else {
      if (
        formikFormValues &&
        formikFormValues.doctor &&
        formikFormValues.patient &&
        selectedDateEvent.dateEvent.start &&
        formikFormValues.session
      ) {
        if (formikFormValues.require_payment) {
          // let paymentCutOffTime =
          //   selectedSession?.node.notification?.downPaymentNotificationCutoff;
          // if (!paymentCutOffTime) {
          //   paymentCutOffTime = settings_obj?.settings?.downpayment_cutoff_time;
          // }
          if (paymentCutOffTime) {
            let currentTime = new Date();
            currentTime.setHours(currentTime.getHours() + paymentCutOffTime);
            currentTime.setMinutes(currentTime.getMinutes() + 30);
            let proposedAppDate = new Date(selectedDateEvent.dateEvent.start);
            if (proposedAppDate > currentTime) {
              return false;
            } else {
              return true;
            }
          }
        } else {
          return false;
        }
      }
      return true;
    }
  };

  const getDoctorSessionDetails = (session) => {
    if (selectedDoctor) {
      let selected_session = selectedDoctor?.node?.eventType?.edges?.find(
        (i) => i.node.id === session
      );
      if (selected_session) {
        return (
          <>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Location: {selected_session.node.eventLocation.title}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Type: {selected_session?.node?.eventType?.title}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Duration: {selected_session.node.duration} Mins
            </Form.Control.Feedback>
          </>
        );
      }
    }
  };

  const handleDiscountChange = (e) => {
    if (e.target.value <= 100) {
      formikRef.current.setFieldValue("discount_value", e.target.value);
    }
  };

  const getDiscountPrice = (discount_value, price) => {
    if (showDiscount) {
      let disc = (discount_value / 100) * price;
      return price - disc;
    } else {
      return price;
    }
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={initialValues}
        innerRef={formikRef}
      >
        {({ handleSubmit, handleChange, values, handleReset }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Container>
              {!isCustomApp ? (
                <Form.Group as={Row} className="text-right mb-4">
                  <Form.Label column sm={4} md={4}>
                    Patient
                  </Form.Label>
                  <Col
                    sm={8}
                    md={8}
                    className={style.form__search}
                    ref={searchPatientRef}
                  >
                    <Form.Control
                      required
                      autoComplete="off"
                      placeholder="Select Patient"
                      type="text"
                      name="patient"
                      value={values.patient}
                      onChange={handleChange}
                      // disabled={editableAppointment ? true : false}
                      className={
                        `mr-sm-2 pr-0 float-left ${style.bg_img_none} ` +
                        editableAppointment
                          ? ""
                          : "pr-5"
                      }
                    />
                    <span
                      onClick={() => setShowPatientSearch(true)}
                      className={"search-icon"}
                    >
                      <i className="fa fa-search"></i>
                    </span>
                  </Col>
                  {selectedPatient?.waitinglist?.edges?.length > 0 && (
                    <Col sm={12} style={{ marginTop: "10px" }}>
                      <Alert variant="warning">
                        This patient is under waiting list. Please click below
                        to see the details.
                        <Button
                          variant="secondary"
                          onClick={handleShow}
                          style={{ marginTop: "10px" }}
                        >
                          Waiting list details
                        </Button>
                      </Alert>
                    </Col>
                  )}
                </Form.Group>
              ) : (
                <Form.Group as={Row} className="text-right mb-4">
                  <Form.Label column sm={4} md={4}>
                    Title
                  </Form.Label>
                  <Col
                    sm={8}
                    md={8}
                    className={style.form__search}
                    ref={searchPatientRef}
                  >
                    <Form.Control
                      required
                      autoComplete="off"
                      placeholder="Title"
                      type="text"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      className={`mr-sm-2 pr-0 float-left pr-5 ${style.bg_img_none}`}
                    />
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row} className="text-right">
                <Form.Label column sm={4} md={4}>
                  Practitioner
                </Form.Label>
                <Col sm={8} md={8}>
                  <Form.Control
                    className={style.bg_img_none}
                    autoComplete="off"
                    as="select"
                    name="doctor"
                    value={values.doctor}
                    onChange={handleDoctor}
                    // disabled={editableAppointment ? true : false}
                    required
                  >
                    <option value="">Select Practitioner</option>
                    {eventData.doctors &&
                      eventData.doctors.edges.map((doctor) => {
                        return (
                          <option
                            value={doctor.node.identifier}
                            key={doctor.node.id}
                          >
                            {doctor.node.firstName} {doctor.node.lastName}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="text-right">
                <Form.Label column sm={4} md={4}>
                  Practitioner Session
                </Form.Label>
                <Col sm={8} md={8}>
                  <Form.Control
                    autoComplete="off"
                    className={style.bg_img_none}
                    required
                    as="select"
                    name="session"
                    id="doctor-session"
                    value={values.session}
                    onChange={handleSession}
                  >
                    <option value="">Select Session</option>
                    {selectedDoctor?.node?.eventType?.edges.map((eventtype) => {
                      return (
                        <option
                          value={eventtype.node.id}
                          key={eventtype.node.id}
                        >
                          {eventtype.node.title}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {selectedDoctor && values.session
                    ? getDoctorSessionDetails(values.session)
                    : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="text-right">
                <Form.Label column sm={4} md={4}>
                  Date
                </Form.Label>
                <Col sm={8} md={8}>
                  <Form.Control
                    onChange={(event) => event.preventDefault()}
                    required
                    autoComplete="off"
                    data-readonly
                    type="text"
                    name="date"
                    value={values.date}
                    className={`mr-sm-2 bg-white ${style.bg_img_none}`}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="text-right">
                <Form.Label column sm={4} md={4}>
                  Time
                </Form.Label>
                <Col sm={8} md={8}>
                  <Form.Control
                    required
                    autoComplete="off"
                    data-readonly
                    onChange={(event) => event.preventDefault()}
                    type="text"
                    name="start"
                    value={values.start}
                    className={`mr-sm-2 bg-white ${style.bg_img_none}`}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm={{ span: 8, offset: 4 }} md={{ span: 8, offset: 4 }}>
                  <span className={style.form__choose}>
                    Click here to
                    <span
                      className={"form__action"}
                      onClick={onChoseAppointment}
                      id="next-appointment"
                    >
                      {" "}
                      choose the next available appointment
                    </span>
                  </span>
                </Col>
              </Form.Group>

              {!isCustomApp ? (
                <>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={4} md={4}>
                      Add to waiting list
                    </Form.Label>
                    <Col sm={8} md={8} className="d-flex align-items-center">
                      <Form.Check
                        className="text-left"
                        type="checkbox"
                        value={showWaitingList}
                        checked={showWaitingList}
                        onChange={() => setShowWaitingList(!showWaitingList)}
                      />
                      <div
                        className={`${style.tooltips} ${style.tooltip__right}`}
                      >
                        <div className={style.tooltip__element}>i</div>
                        <div className={style.tooltip__info}>
                          <p className="mb-0">
                            If an earlier appointment slot becomes available,
                            notify this person may be selected to fill that slot
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className={showWaitingList ? " " : " d-none "}
                  >
                    <Form.Label column sm={4} md={4} className="text-right">
                      Waiting list
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        as="select"
                        autoComplete="off"
                        name="waiting_list"
                        id="waiting_list"
                        value={values.waiting_list}
                        onChange={(event) => {
                          handleChange(event);

                          const waitValue = waitingList?.find(
                            (item) => item.node.id === event.target.value
                          );

                          setActiveWaitingList(waitValue?.node);
                        }}
                      >
                        <option value="">Select waiting list</option>
                        {waitingList
                          ? waitingList.map((waiting) => {
                              return (
                                <option
                                  value={waiting.node.id}
                                  key={waiting.node.id}
                                >
                                  {waiting.node.name}
                                </option>
                              );
                            })
                          : null}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  {showWaitingList && (
                    <>
                      {!activeWaitingList?.name
                        ?.toLowerCase()
                        .includes("new") && (
                        <Form.Group as={Row} className="text-right">
                          <Form.Label column sm={4} md={4}>
                            Last seen
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <DatePicker
                              selected={lastSeenDate ? lastSeenDate : ""}
                              onChange={(info) => setLastSeenDate(info)}
                              name="lastSeenDate"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "5px, 10px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                            />
                          </Col>
                        </Form.Group>
                      )}

                      {activeWaitingList?.name
                        ?.toLowerCase()
                        .includes("new") ? (
                        <>
                          <Form.Group as={Row} className="text-right">
                            <Form.Label column sm={4} md={4}>
                              Called date
                            </Form.Label>
                            <Col sm={8} md={8}>
                              <DatePicker
                                selected={calledDate ? calledDate : ""}
                                onChange={(info) => setCalledDate(info)}
                                name="calledDate"
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                required
                                popperModifiers={{
                                  offset: {
                                    enabled: true,
                                    offset: "5px, 10px",
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    escapeWithReference: false,
                                    boundariesElement: "viewport",
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="text-right">
                            <Form.Label column sm={4} md={4}>
                              Should be seen start date
                            </Form.Label>
                            <Col sm={8} md={8}>
                              <DatePicker
                                selected={initialDate ? initialDate : ""}
                                onChange={(info) => setinitialDate(info)}
                                name="initialDate"
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                popperModifiers={{
                                  offset: {
                                    enabled: true,
                                    offset: "5px, 10px",
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    escapeWithReference: false,
                                    boundariesElement: "viewport",
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="text-right">
                            <Form.Label column sm={4} md={4}>
                              Should be seen end date
                            </Form.Label>
                            <Col sm={8} md={8}>
                              <DatePicker
                                selected={lastDate ? lastDate : ""}
                                onChange={(info) => setlastDate(info)}
                                name="lastDate"
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                popperModifiers={{
                                  offset: {
                                    enabled: true,
                                    offset: "5px, 10px",
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    escapeWithReference: false,
                                    boundariesElement: "viewport",
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                        </>
                      ) : activeWaitingList?.isUrgent ? (
                        <Form.Group as={Row} className="text-right">
                          <Form.Label column sm={4} md={4}>
                            Called date
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <DatePicker
                              selected={calledDate ? calledDate : ""}
                              onChange={(info) => setCalledDate(info)}
                              name="calledDate"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              required
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "5px, 10px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                            />
                          </Col>
                        </Form.Group>
                      ) : (
                        <>
                          <Form.Group as={Row} className="text-right">
                            <Form.Label column sm={4} md={4}>
                              Should be seen start date
                            </Form.Label>
                            <Col sm={8} md={8}>
                              <DatePicker
                                selected={initialDate ? initialDate : ""}
                                onChange={(info) => setinitialDate(info)}
                                name="initialDate"
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                popperModifiers={{
                                  offset: {
                                    enabled: true,
                                    offset: "5px, 10px",
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    escapeWithReference: false,
                                    boundariesElement: "viewport",
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="text-right">
                            <Form.Label column sm={4} md={4}>
                              Should be seen end date
                            </Form.Label>
                            <Col sm={8} md={8}>
                              <DatePicker
                                selected={lastDate ? lastDate : ""}
                                onChange={(info) => setlastDate(info)}
                                name="lastDate"
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                popperModifiers={{
                                  offset: {
                                    enabled: true,
                                    offset: "5px, 10px",
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    escapeWithReference: false,
                                    boundariesElement: "viewport",
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                        </>
                      )}

                      <Form.Group as={Row}>
                        <Form.Label
                          column
                          sm={12}
                          md={12}
                          className="text-left"
                        >
                          Notes for waiting list
                        </Form.Label>
                        <Col sm={12} md={12}>
                          <Form.Control
                            autoComplete="off"
                            as="textarea"
                            rows={5}
                            name="waitingListNotes"
                            value={values.waitingListNotes}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                    </>
                  )}

                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={4} md={4}>
                      Auto cancellation
                    </Form.Label>
                    <Col sm={8} md={8} className="d-flex align-items-center">
                      <Form.Check
                        className="text-left"
                        type="checkbox"
                        name="require_payment"
                        onChange={handleChange}
                        value={values.require_payment}
                        checked={values.require_payment}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={4} md={4}>
                      Apply Discount
                    </Form.Label>
                    <Col sm={8} md={8} className="d-flex align-items-center">
                      <Form.Check
                        className="text-left"
                        type="checkbox"
                        value={showDiscount}
                        checked={showDiscount}
                        onChange={() => setShowDiscount(!showDiscount)}
                      />
                    </Col>
                  </Form.Group>
                  {showDiscount ? (
                    <Form.Group as={Row} className="text-right">
                      <Form.Label column sm={4} md={4} className="text-right">
                        Discount %
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          type="number"
                          step="any"
                          name="discount_value"
                          onInput={(e) => validateDecimal(e)}
                          autoComplete="off"
                          onChange={handleDiscountChange}
                          value={
                            values.discount_value ? values.discount_value : ""
                          }
                        />
                      </Col>
                    </Form.Group>
                  ) : null}
                  {selectedSession && selectedSession.node.price ? (
                    <h6 className="text-primary-color">
                      {" "}
                      Price :{" "}
                      {values.discount_value > 0
                        ? getDiscountPrice(
                            values.discount_value,
                            selectedSession.node.price
                          )
                        : selectedSession.node.price}{" "}
                      KWD
                    </h6>
                  ) : null}
                  <Form.Group as={Row}>
                    <Form.Label column sm={12} md={12} className="text-left">
                      Notes about the session
                    </Form.Label>
                    <Col sm={12} md={12}>
                      <Form.Control
                        autoComplete="off"
                        as="textarea"
                        rows={5}
                        name="descriptionEn"
                        value={values.descriptionEn}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </>
              ) : null}

              <Form.Group className="justify-content-between d-flex">
                <Button
                  className={style.form__button}
                  variant="primary"
                  onClick={() => onCancelForm(handleReset)}
                  size="sm"
                >
                  Cancel
                </Button>
                <Button
                  className={style.form__button}
                  disabled={isSubmitDisabled(values) || isSubmitting}
                  onClick={() => (isSubmitting ? null : handleSubmit())}
                  variant="primary"
                  size="sm"
                >
                  {isSubmitting ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form.Group>
            </Container>
          </Form>
        )}
      </Formik>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Waiting list details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            striped
            bordered
            responsive
            className="table waiting_table text-xs-left"
            style={{ minWidth: "100%" }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ verticalAlign: "middle" }}>
                  Practitioner
                </th>
                <th scope="col" style={{ verticalAlign: "middle" }}>
                  Waiting list
                </th>
                <th scope="col" style={{ verticalAlign: "middle" }}>
                  Called date
                </th>
                <th scope="col" style={{ verticalAlign: "middle" }}>
                  Should be seen start
                </th>
                <th scope="col" style={{ verticalAlign: "middle" }}>
                  Should be seen end
                </th>
                <th scope="col" style={{ verticalAlign: "middle" }}>
                  Notes
                </th>
                <th scope="col" style={{ verticalAlign: "middle" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedPatient?.waitinglist?.edges.map((patientobj, index) => {
                const startDate = patientobj.node.shouldBeSeenStart
                  ? new Date(patientobj.node.shouldBeSeenStart)
                  : null;

                const calledDate = patientobj.node.calledDate
                  ? new Date(patientobj.node.calledDate)
                  : null;

                const endDate = patientobj.node.shouldBeSeenEnd
                  ? new Date(patientobj.node.shouldBeSeenEnd)
                  : null;

                const doctor = `${patientobj.node.event.doctor.firstName} ${patientobj.node.event.doctor.lastName}`;

                return (
                  <tr key={index}>
                    <td style={{ verticalAlign: "middle" }}>{doctor}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      {patientobj.node.waitingList.name}
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      {calledDate ? getDate(calledDate) : "-"}
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      {startDate ? getDate(startDate) : "-"}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      {endDate ? getDate(endDate) : "-"}
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      {patientobj.node.waitingListNotes}
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <Button
                        variant="link"
                        className="danger-color"
                        onClick={() =>
                          deleteWaitingRecordClicked(patientobj.node.id)
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withRouter(CreateEventForm);
