import React, { useCallback, useEffect, useMemo, useState } from "react";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import {
  concatAllErrors,
  ERROR_MESSAGE,
  error_options,
  SNACK_DURATION,
  success_options,
  getDate,
} from "Common/helpers";
import {
  Card,
  Button,
  Table,
  Container,
  Col,
  Row,
  Modal,
  Form,
  Dropdown,
  Badge,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useSnackbar } from "react-simple-snackbar";
import Base from "Views/base.js";

const frequencyOptions = {
  once_daily: "Once daily",
  twice_daily: "Twice daily",
  three_times_a_day: "Thrice a day",
  four_times_a_days: "Four times a days",
  others: "Others",
};

const routeOptions = {
  per_oral: "PO",
  iv: "IV",
  im: "IM",
  id: "ID",
  nasal: "Nasal Spray",
};

const quantityOptions = {
  one: "1 Month",
  two: "2 Month",
  three: "3 Month",
  four: "4 Month",
  five: "5 Month",
  six: "6 Month",
  seven: "7 Month",
  eight: "8 Month",
  nine: "9 Month",
  ten: "10 Month",
  eleven: "11 Month",
  twelve: "12 Month",
};

const PrescriptionDetail = (props) => {
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [deliveryType, setDeliveryType] = useState(null);
  const [show, setShow] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState("");
  const prescriptionId = props.match.params.prescriptionId;

  const handleClose = () => setShow(false);

  const PRESCRIPTION_DETAIL = gql`
    query GetPrescription($id: ID!) {
      prescription(id: $id) {
        pk
        id
        created
        date
        hasStamp
        file
        sentToPharmacy
        sentToPatientReadyInPharmacy
        onlySentToPatient
        pharmacy {
          id
          pk
        }
        doctor {
          id
          firstName
          lastName
        }
        patient {
          firstName
          lastName
          civilId
          identifier
          phone
          dob
          id
        }
        lines {
          edges {
            node {
              id
              medication
              quantity
              frequency
              route
              dosage
              rawData
              others
            }
          }
        }
        prescriptionRequest {
          created
          status
          shippingDisplay
          patientNotes
          pharmacy {
            pk
            id
            titleEn
            descriptionEn
            addressEn
            number
          }
        }
      }
    }
  `;

  const DELETE_PRESCRIPTION_ITEM = gql`
    mutation deletePrescriptionItem($id: ID!) {
      deletePrescriptionItem(id: $id) {
        deleted
      }
    }
  `;

  const GENERATE_RAW_PDF = gql`
    mutation generatePrescriptionPdf($id: Int!, $withStamp: Boolean) {
      generatePrescriptionPdf(id: $id, withStamp: $withStamp) {
        obj {
          file
        }
      }
    }
  `;

  const SEND_PRESCRIPTION = gql`
    mutation sendPrescription($id: Int!, $sendType: String, $pharmacy: Int) {
      sendPrescription(id: $id, sendType: $sendType, pharmacy: $pharmacy) {
        obj {
          file
        }
      }
    }
  `;

  const PHARMACIES = gql`
    query {
      pharmacies {
        edges {
          node {
            pk
            id
            titleEn
            descriptionEn
            addressEn
            number
          }
        }
      }
    }
  `;

  const [getPharmacies, { data: pharmacies }] = useLazyQuery(PHARMACIES, {
    fetchPolicy: "network-only",
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  // get the details of this prescription
  const [getPrescription, { data: item }] = useLazyQuery(PRESCRIPTION_DETAIL, {
    fetchPolicy: "network-only",
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  // Trigger the api on load
  useEffect(() => {
    getPrescription({ variables: { id: prescriptionId } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (item?.prescription?.prescriptionRequest?.pharmacy?.id) {
      setSelectedPharmacy({
        node: item?.prescription?.prescriptionRequest?.pharmacy,
      });
    }
  }, [item?.prescription?.prescriptionRequest?.pharmacy]);

  // Delete item
  const [deletePrescriptionItem] = useMutation(DELETE_PRESCRIPTION_ITEM, {
    onCompleted({ deletePrescriptionItem }) {
      if (deletePrescriptionItem) {
        getPrescription({ variables: { id: prescriptionId } });
      }
    },
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [genereatePdf, { loading: generatePdfLoader }] = useMutation(
    GENERATE_RAW_PDF,
    {
      onCompleted({ generatePrescriptionPdf }) {
        if (generatePrescriptionPdf) {
          getPrescription({ variables: { id: prescriptionId } });
          openSnackbarSuccess("PDF generated.", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [sendPrescription, { loading: sendPrescriptionLoader }] = useMutation(
    SEND_PRESCRIPTION,
    {
      onCompleted({ sendPrescription }) {
        if (sendPrescription) {
          getPrescription({ variables: { id: prescriptionId } });
          openSnackbarSuccess("Prescription sent.", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const deletePrescription = (prescItem) => {
    let variables = {
      id: prescItem.node.id,
    };
    if (window.confirm("Are you sure you want to delete this prescription?")) {
      deletePrescriptionItem({ variables });
    }
  };

  useEffect(() => {
    if (item && !item?.prescription?.file) {
      genereatePdf({ variables: { id: item?.prescription?.pk } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleDeliveryWithPharmacy = (home = false) => {
    setShow(true);

    if (home) {
      setDeliveryType("send_to_pharmacy_home");
      // home delivery
    } else {
      setDeliveryType("send_to_pharmacy");
      // pick up
    }
  };

  const renderActions = (item) => {
    const node = item?.prescription;

    const isNotActive = generatePdfLoader || sendPrescriptionLoader;

    return (
      <Dropdown>
        <Dropdown.Toggle
          variant="primary"
          id="dropdown-basic"
          disabled={isNotActive}
        >
          {isNotActive ? (
            <Spinner animation="border" variant="success" size="sm" />
          ) : (
            "Options"
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {node?.hasStamp ? (
            <Dropdown.Item
              onClick={() =>
                genereatePdf({ variables: { id: node?.pk, withStamp: false } })
              }
            >
              Generate without stamp
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              onClick={() =>
                genereatePdf({ variables: { id: node?.pk, withStamp: true } })
              }
            >
              Generate with stamp
            </Dropdown.Item>
          )}

          {node?.file && (
            <>
              <Dropdown.Item onClick={() => handleDeliveryWithPharmacy(true)}>
                Home Delivery
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeliveryWithPharmacy()}>
                Pick up from the pharmacy
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={sendToPatientPharmacy}>
                Send to patient to pick up on pharmacy
              </Dropdown.Item> */}

              <Dropdown.Item onClick={sendDirectlyPatient}>
                Send as PDF to patient
              </Dropdown.Item>

              <Dropdown.Item href={node.file} target={"_blank"}>
                Print
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  useEffect(() => {
    getPharmacies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendToPharmacy = useCallback(() => {
    if (
      window.confirm(
        `Are you sure you want to send this to Pharmacy (${selectedPharmacy?.node?.titleEn})?`
      )
    ) {
      const payload = {
        id: item?.prescription?.pk,
        pharmacy: selectedPharmacy?.node?.pk,
        sendType: deliveryType,
      };

      sendPrescription({ variables: payload });

      handleClose();
    }
  }, [
    deliveryType,
    item?.prescription?.pk,
    selectedPharmacy?.node?.pk,
    selectedPharmacy?.node?.titleEn,
    sendPrescription,
  ]);

  // const sendToPatientPharmacy = useCallback(() => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to send this to patient letting them know its available on the pharmacy?`
  //     )
  //   ) {
  //     const payload = {
  //       id: item?.prescription?.pk,
  //       sendType: "send_to_patient_ready_pharmacy",
  //     };

  //     sendPrescription({ variables: payload });

  //     handleClose();
  //   }
  // }, [item?.prescription?.pk, sendPrescription]);

  const sendDirectlyPatient = useCallback(() => {
    if (window.confirm(`Are you sure you want to send this to patient?`)) {
      const payload = {
        id: item?.prescription?.pk,
        sendType: "send_direct_patient",
      };

      sendPrescription({ variables: payload });

      handleClose();
    }
  }, [item?.prescription?.pk, sendPrescription]);

  const handlePharmacyChange = useCallback(
    (event) => {
      const selected = pharmacies?.pharmacies?.edges?.find(
        (item) => item.node.id === event.target.value
      );

      setSelectedPharmacy(selected);
    },
    [pharmacies?.pharmacies]
  );

  const renderPharmacySection = useMemo(() => {
    return (
      <>
        <Modal size="sm" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Send to pharmacy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label className="w-100">
              Pharmacies
              <Form.Control
                autoComplete="off"
                as="select"
                name="pharmacy"
                value={selectedPharmacy ? selectedPharmacy?.node?.id : ""}
                onChange={handlePharmacyChange}
              >
                <option value="">Select Pharmacy</option>
                {pharmacies?.pharmacies?.edges?.map((obj, index) => {
                  return (
                    <option value={obj.node.id} key={index}>
                      {obj.node.titleEn}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Label>
            {selectedPharmacy && (
              <>
                <p className="mb-0">
                  Name: <strong>{selectedPharmacy.node.titleEn}</strong>
                </p>
                <p className="mb-0">
                  Description:{" "}
                  <strong>{selectedPharmacy.node.descriptionEn || "-"}</strong>
                </p>
                <p className="mb-0">
                  Address:{" "}
                  <strong>{selectedPharmacy.node.addressEn || "-"}</strong>
                </p>
                <p className="mb-0">
                  Phone: <strong>{selectedPharmacy.node.number || "-"}</strong>
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={sendToPharmacy}
              disabled={!selectedPharmacy}
            >
              Send to pharmacy
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }, [
    handlePharmacyChange,
    pharmacies,
    selectedPharmacy,
    sendToPharmacy,
    show,
  ]);

  return (
    <Base title={"Prescription details"} showHeader={true}>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title className="d-flex flex-row justify-content-between ">
                  <div>
                    <p className="mb-0">
                      File number:{" "}
                      <strong>{item?.prescription?.patient?.identifier}</strong>
                    </p>
                    <p className="mb-0">
                      Name:{" "}
                      <strong>{`${item?.prescription?.patient?.firstName} ${item?.prescription?.patient?.lastName}`}</strong>
                    </p>
                    <p className="mb-0">
                      Civil ID:{" "}
                      <strong>
                        {item?.prescription?.patient?.civilId || "-"}
                      </strong>
                    </p>
                    <p className="mb-0">
                      Phone:{" "}
                      <strong>{item?.prescription?.patient?.phone}</strong>
                    </p>
                  </div>
                  <div>
                    <p className="mb-0">
                      Prescribed By :{" "}
                      <strong>
                        {item?.prescription?.doctor?.firstName}{" "}
                        {item?.prescription?.doctor?.lastName}
                      </strong>
                    </p>
                    <p className="mb-0">
                      Date:{" "}
                      <strong>
                        {item?.prescription?.date
                          ? getDate(new Date(item?.prescription?.date))
                          : getDate(new Date(item?.prescription?.created))}
                      </strong>
                    </p>
                    {item?.prescription?.prescriptionRequest && (
                      <p className="mb-0">
                        Requested date:{" "}
                        <strong>
                          {item?.prescription?.prescriptionRequest?.created
                            ? getDate(
                                new Date(
                                  item?.prescription?.prescriptionRequest?.created
                                )
                              )
                            : ""}
                        </strong>
                      </p>
                    )}
                  </div>
                  <div>
                    <Badge variant="secondary">
                      {item?.prescription?.prescriptionRequest?.shippingDisplay
                        ? item?.prescription?.prescriptionRequest
                            ?.shippingDisplay
                        : "Regular"}{" "}
                      {item?.prescription?.prescriptionRequest?.pharmacy
                        ?.titleEn
                        ? `(${item?.prescription?.prescriptionRequest?.pharmacy?.titleEn})`
                        : null}
                    </Badge>
                  </div>
                  <div>{renderActions(item)}</div>
                </Card.Title>
                {item?.prescription?.prescriptionRequest?.patientNotes && (
                  <Alert variant="info">
                    <h4>Request notes:</h4>
                    <pre>
                      {item?.prescription?.prescriptionRequest?.patientNotes}
                    </pre>
                  </Alert>
                )}

                <Table responsive className=" ">
                  <thead>
                    <tr>
                      <th scope="col " className="text-uppercase">
                        Medication
                      </th>
                      <th scope="col " className="text-uppercase">
                        Dosage
                      </th>
                      <th scope="col " className="text-uppercase">
                        Frequency
                      </th>
                      <th scope="col " className="text-uppercase">
                        Route
                      </th>
                      <th scope="col " className="text-uppercase">
                        Quantity
                      </th>
                      <th scope="col " className="text-uppercase text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.prescription?.lines?.edges.map((i, index) => {
                      let freq = frequencyOptions[i.node.frequency];

                      if (i.node.frequency === "others") {
                        freq = i.node.others;
                      }

                      return (
                        <tr key={index} className="patient_table_row">
                          <td>{i.node.medication}</td>
                          <td>{i.node.dosage}</td>
                          <td>{freq}</td>
                          <td>{routeOptions[i.node.route]}</td>
                          <td>{quantityOptions[i.node.quantity]}</td>
                          <td>
                            <Button
                              variant="link"
                              size="sm"
                              className="m-0 w-100"
                              onClick={() => deletePrescription(i)}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  {/* } */}
                </Table>
                <p className="mb-0">
                  {item?.prescription?.sentToPharmacy ? (
                    <i className="fa fa-check mr-2" aria-hidden="true"></i>
                  ) : (
                    <i
                      className="fa fa-exclamation-circle mr-2"
                      aria-hidden="true"
                    ></i>
                  )}
                  Sent to pharmacy
                </p>
                <p className="mb-0">
                  {item?.prescription?.sentToPatientReadyInPharmacy ? (
                    <i className="fa fa-check mr-2" aria-hidden="true"></i>
                  ) : (
                    <i
                      className="fa fa-exclamation-circle mr-2"
                      aria-hidden="true"
                    ></i>
                  )}
                  Sent to patient to pick up on pharmacy
                </p>
                <p className="mb-0">
                  {item?.prescription?.onlySentToPatient ? (
                    <i className="fa fa-check mr-2" aria-hidden="true"></i>
                  ) : (
                    <i
                      className="fa fa-exclamation-circle mr-2"
                      aria-hidden="true"
                    ></i>
                  )}
                  Prescription sent directly to patient
                </p>
                {renderPharmacySection}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Base>
  );
};

export default PrescriptionDetail;
