import React from "react";
import style from "./LoginForm.module.css";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { gql, useMutation } from "@apollo/client";
import { isLoggedInVar, userDetailsVar } from "../../../cache/cache.js";
import { withRouter } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "../../../Common/helpers.js";
import * as Sentry from "@sentry/browser";

const LoginForm = (props) => {
  const [openSnackbarError] = useSnackbar(error_options);

  //=======================LOGIN==========================//

  const LOGIN = gql`
    mutation tokenAuth($email: String!, $password: String!) {
      tokenAuth(input: { email: $email, password: $password }) {
        success
        errors
        unarchiving
        token
        refreshToken
        unarchiving
        user {
          id
          username
          doctor {
            id
            identifier
          }
        }
      }
    }
  `;

  const [tokenAuth, { loading }] = useMutation(LOGIN, {
    onCompleted({ tokenAuth }) {
      if (tokenAuth.success === false) {
        let errors = tokenAuth.errors.nonFieldErrors;
        for (let i in errors) {
          let error = errors[i];
          openSnackbarError(error.message, [SNACK_DURATION]);
        }
      } else if (tokenAuth.success === true) {
        const { history } = props;
        localStorage.setItem("token", tokenAuth.token);
        localStorage.setItem("user", JSON.stringify(tokenAuth.user));
        userDetailsVar(JSON.stringify(tokenAuth.user));
        isLoggedInVar(true);
        if (
          props.location &&
          props.location.state &&
          props.location.state.from
        ) {
          history.push(props.location.state.from.pathname);
        } else {
          history.push("/dashboard");
        }
      }
    },
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("tokenAuth error " + err);

      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    tokenAuth({
      variables: { email: values.email, password: values.password },
    });
    resetForm({ values: "" });
  };

  // const validate = (values) => {
  //   let errors = {};
  //   if (!values.username) {
  //     errors.username = 'Please enter a email.';
  //   }
  //   else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)){
  //     errors.username = 'Please enter a valid email.';
  //   }
  //   if (!values.password) {
  //     errors.password = 'Please enter a password.';
  //   }
  //   return errors;
  // };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Col xs={12} md={11} lg={11}>
        <Form.Group as={Row} className="mb-4">
          <Form.Label column xs={12} sm={3} className={"text-white text-right"}>
            Email
          </Form.Label>
          <Col xs={12} sm={9}>
            <Form.Control
              type="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              autoComplete="username"
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column xs={12} sm={3} className={"text-white text-right"}>
            Password
          </Form.Label>
          <Col xs={12} sm={9}>
            <Form.Control
              autoComplete="new-password"
              type="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              required
            />
          </Col>
        </Form.Group>
        <Button
          variant="link"
          onClick={() => props.history.push("/forgot/password")}
          block
          className="forgot_password"
        >
          {" "}
          Forgot password?
        </Button>
        <Form.Group as={Row}></Form.Group>
        <Form.Group as={Row}>
          <Col className={style.login_form__submit}>
            <Button
              disabled={loading}
              className="login_form__button"
              type="submit"
            >
              {loading ? "Loading ..." : "Submit"}
            </Button>
          </Col>
        </Form.Group>
      </Col>
    </Form>
  );
};

export default withRouter(LoginForm);
