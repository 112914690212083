import "./Calendar.css";
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import timeGridWeek from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  dateEventInVar,
  calendarSlotsVar,
  eventsDataVar,
  calendarActiveMonthVar,
  isCustomAppVar,
  appFromCalVar,
  displayLoaderVar,
  myAppointmentDetailsVar,
} from "../../../cache/cache.js";
import { urlActions } from "../../../Common/helpers.js";
import { withRouter } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { Link } from "react-router-dom";
import Preloader from "../../../Common/Preloder/Preloader";

import { API } from "@sentry/core";

const Calendar = (props) => {
  const {
    myAppointments,
    selectedDoctor,
    doctorLeavesList,
    fullCalendarRef,
    history,
    selectedDateEvent,
    selectedSession,
    referralObjectData,
    GetEvents,
    eventsLoading,
    editableAppointment,
  } = props;

  const [calendarView, setCalendarView] = useState("timeGridWeek");
  const [calendarSlotDuration, setCalendarSlotDuration] = useState(30);
  const myAppointmentDetails = useReactiveVar(myAppointmentDetailsVar);
  const [activeDocShiftArr, setActiveDocShiftArr] = useState([]);
  const [amountOfEvents, setAmountOfEvents] = useState(25);
  const [data, setData] = useState([]);
  const [coords, setcoords] = useState(null);
  const [calMounted, setCalMounted] = useState(false);
  const [selectedDateStr, setSelectedDateStr] = useState(null);
  const [availableSlotsArr, setAvailableSlotsArr] = useState([]);
  const eventsDataMain = useReactiveVar(eventsDataVar);
  const calendarActiveMonth = useReactiveVar(calendarActiveMonthVar);
  const appFromCal = useReactiveVar(appFromCalVar);
  const displayLoader = useReactiveVar(displayLoaderVar);
  const appCreateFromCal = props?.location?.state?.appCreateFromCal;
  const [time, setTime] = React.useState(null);
  const [calendarBodyHeight, setCalendarBodyHeight] = useState({});
  let calendarExtraProps = {};

  const [isCustom, setIsCustom] = useState(false);

  useEffect(() => {
    // Get the query string part of the URL
    const queryString = window.location.search;
    // Parse the query string
    const urlParams = new URLSearchParams(queryString);

    // Check if the parameter 'isCustom' exists and is set to 'true'
    if (urlParams.get("isCustom") === "true") {
      setIsCustom(true);
    }
  }, []);

  useEffect(() => {
    if (selectedSession) {
      let duration = selectedSession.node.duration;
      setCalendarSlotDuration(duration);
    }
  }, [selectedSession]);

  // ======================================== DOCTOR AND SESSION AVAILABILITY ================================
  let weekdayAvailability = [];
  let businessHours = [];
  let workingHours = [];

  // if availability exists for the selected session/service- use that, otherwise use availability for doctor shifts
  // Weekday availability and shift hours based on Selected SERVICE
  if (
    selectedSession &&
    selectedSession?.node?.availability &&
    selectedSession?.node?.availability?.edges?.length > 0
  ) {
    weekdayAvailability = selectedSession?.node?.availability?.edges?.map(
      (available) => parseInt(available.node.day)
    );
    selectedSession?.node?.availability?.edges?.map((available) => {
      let shifts = available.node.shifts.edges;
      for (var i = 0; i < shifts.length; i++) {
        let shift = shifts[i];
        let dayArr = Array.from(String(available.node.day), Number);
        workingHours.push({
          daysOfWeek: dayArr,
          startTime: shift.node.startTime.substring(0, 5),
          endTime: shift.node.endTime.substring(0, 5),
        });
      }
      return null;
    });
  } else if (
    // Weekday availability and buisness hours based on DOCTOR
    selectedDoctor &&
    selectedDoctor?.node?.availability &&
    selectedDoctor?.node?.availability?.edges?.length > 0
  ) {
    weekdayAvailability = selectedDoctor?.node?.availability?.edges?.map(
      (available) => parseInt(available.node.day)
    );
    selectedDoctor?.node?.availability?.edges?.map((available) => {
      let shifts = available.node.shifts.edges;
      for (var i = 0; i < shifts.length; i++) {
        let shift = shifts[i];
        let dayArr = Array.from(String(available.node.day), Number);
        workingHours.push({
          daysOfWeek: dayArr,
          startTime: shift.node.startTime.substring(0, 5),
          endTime: shift.node.endTime.substring(0, 5),
        });
      }
      return null;
    });
  }

  let defaultHours = {
    daysOfWeek: weekdayAvailability,
    startTime: "09:00",
    endTime: "17:00",
  };
  let defaultBuisness = [];
  defaultBuisness.push(defaultHours);
  calendarExtraProps["selectConstraint"] = "businessHours";
  if (workingHours && workingHours.length > 0) {
    businessHours = workingHours;
  } else {
    businessHours = defaultBuisness;
  }

  calendarExtraProps["businessHours"] = businessHours;

  // ======================================== DOCTOR AND SESSION AVAILABILITY END ================================

  const addMinutes = (time, minsToAdd) => {
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }
    var piece = time.split(":");
    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
  };

  function compareTimes(time1, time2) {
    function parseTime(time) {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes; // Convert time to total minutes since midnight
    }

    const time1Minutes = parseTime(time1);
    const time2Minutes = parseTime(time2);

    if (time1Minutes < time2Minutes) {
      return -1; // time1 is earlier than time2
    } else if (time1Minutes > time2Minutes) {
      return 1; // time1 is later than time2
    } else {
      return 0; // time1 is the same as time2
    }
  }

  const handleChangeView = (daySelect) => {
    if (daySelect.view.type === "dayGridMonth") {
      daySelect.view.calendar.changeView(
        "timeGridDay",
        `${daySelect.startStr}`
      );
      setCalendarView("timeGridDay");
    } else if (daySelect.view.type === "timeGridDay") {
      return false;
    }
  };

  const handleSelectAllow = (info) => {
    if (calendarView === "dayGridMonth" || info.allDay) {
      return true;
    } else {
      let allAvailableEvents =
        fullCalendarRef && fullCalendarRef.current
          ? fullCalendarRef?.current?.getApi()?.getEvents()
          : [];
      let is_allowed = allAvailableEvents.find(
        (i) => i.startStr === info.startStr
      );
      if (is_allowed) {
        return true;
      } else {
        return false;
      }
    }
  };
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    localStorage.setItem("calendarViewStored", "");
    //To initialize calendar based on url params
    let calendarInitDate = urlActions(
      window.location.href,
      "get",
      "calendarInitDate"
    );
    let calendarInitView = urlActions(
      window.location.href,
      "get",
      "calendarInitView"
    );
    if (calendarInitView && calendarInitDate) {
      if (calendarInitDate && calendarInitDate.indexOf(" ") > -1) {
        calendarInitDate = calendarInitDate.replace(" ", "+");
      }
      let dtSt = new Date(calendarInitDate);
      dtSt.setHours(1);
      let endDate = new Date(dtSt);
      endDate.setDate(dtSt.getDate() + 30);

      displayLoaderVar(true);

      if (dtSt) {
        let calDate = dtSt.toISOString();
        fullCalendarRef?.current
          ?.getApi()
          ?.changeView(calendarInitView, calDate);
      }
    }
    setCalMounted(true);
    return () => window.removeEventListener("resize", updateWidth);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let calendarViewStored = localStorage.getItem("calendarViewStored");

  useEffect(() => {
    if (calendarViewStored) {
      setCalendarView(calendarViewStored);
    }
  }, [calendarViewStored]);

  // FUNCTION THAT CALCULATES  AVAILABLE SLOTS
  const getAllAvailableSlots = (start_date, end_date, appData) => {
    let refCalendarView =
      fullCalendarRef && fullCalendarRef.current
        ? fullCalendarRef?.current?.getApi()?.view
        : null;
    let doctor_conditions = selectedDoctor && !!selectedDoctor.node.identifier;
    let session_conditions =
      selectedSession && selectedSession !== null ? true : false;
    if (
      referralObjectData &&
      referralObjectData.referralObj &&
      referralObjectData.referralObj.referral
    ) {
      doctor_conditions = true;
      session_conditions = true;
    }
    if (doctor_conditions && session_conditions && !myAppointments) {
      // var today = new Date();
      var today = start_date;
      if (today < new Date()) {
        today = new Date();
      }
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();
      let buffer_minutes = selectedSession?.node
        ? parseInt(selectedSession?.node?.buffer)
        : 0; // change default to 0 after testing

      // YYYY-MM-DD
      var todayDateStr = yyyy + "-" + mm + "-" + dd;
      let raw_events = data;
      let holidays = [];

      doctorLeavesList?.leaves?.edges?.map((leave) => {
        let startDate = new Date(leave.node.startDate);
        let endDate = new Date(leave.node.endDate);
        for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
          let activeD = new Date(d);
          let dd = String(activeD.getDate()).padStart(2, "0");
          let mm = String(activeD.getMonth() + 1).padStart(2, "0");
          let yyyy = activeD.getFullYear();
          // YYYY-MM-DD
          let dateStr = yyyy + "-" + mm + "-" + dd;
          holidays.push(dateStr);
        }
      });

      var allevents = [];
      // today is new date because we do not care about creating slots for previous days
      for (var d = today; d <= end_date; d.setDate(d.getDate() + 1)) {
        // date in the range
        let activeDate = new Date(d);

        let dd = String(activeDate.getDate()).padStart(2, "0");
        let nextDd = String(activeDate.getDate() + 1).padStart(2, "0");
        let mm = String(activeDate.getMonth() + 1).padStart(2, "0");
        let yyyy = activeDate.getFullYear();
        // YYYY-MM-DD
        let activeDateStr = yyyy + "-" + mm + "-" + dd;

        let activeDateStrWhileLoopStart = "01/01/2011 ";
        let activeDateStrWhileLoopEnd = "01/01/2011 ";

        let nextDate = new Date(activeDateStr);
        nextDate.setDate(nextDate.getDate() + 1);
        let ddN = String(nextDate.getDate()).padStart(2, "0");
        let mmN = String(nextDate.getMonth() + 1).padStart(2, "0");
        let yyyyN = nextDate.getFullYear();
        let nextDateStr = yyyyN + "-" + mmN + "-" + ddN;

        let businesshours = calendarExtraProps["businessHours"];
        let hasOverrideShift = false;
        let activeDateCustomShift = null;
        if (selectedDoctor?.node?.overrideSchedules?.edges.length > 0) {
          activeDateCustomShift =
            selectedDoctor?.node?.overrideSchedules?.edges.find(
              (i) => i.node.date === activeDateStr
            );
          if (activeDateCustomShift) {
            hasOverrideShift = true;
          }
        }

        // Check if date is on holiday do nothing
        if (
          !holidays.includes(activeDateStr) &&
          (weekdayAvailability.includes(activeDate.getDay()) ||
            hasOverrideShift)
        ) {
          // business start and endtime
          let calendarBusinesshours = businesshours.filter((i) =>
            i.daysOfWeek.includes(activeDate.getDay())
          );

          if (hasOverrideShift && activeDateCustomShift) {
            calendarBusinesshours = activeDateCustomShift.node.shifts.edges.map(
              (i) => {
                return {
                  startTime: i.node.startTime.slice(0, 5), // to convert from ex: 15:00:00 (backend returns) to 15:00 (as needed by our func)
                  endTime: i.node.endTime.slice(0, 5), // to convert from ex:  15:00:00 (backend returns) to 15:00 (as needed by our func)
                  daysOfWeek: [activeDate.getDay()],
                };
              }
            );
          }

          var calendarBusinesshoursSorted = calendarBusinesshours.sort(
            (a, b) => parseInt(a.startTime) - parseInt(b.startTime)
          );
          for (var a in calendarBusinesshoursSorted) {
            let businesshour = calendarBusinesshoursSorted[a];
            let startTime = businesshour.startTime;
            let endTime = businesshour.endTime;

            // initial time for creation of events
            let activeStartTime = startTime;
            let hasOverlapSlotFromPrevDay = false;
            hasOverlapSlotFromPrevDay = allevents.find((i) => {
              let existingSlotStart = new Date(i.start);
              let existingSlotEnd = new Date(i.end);
              let slotToBeChecked = new Date(existingSlotEnd);
              slotToBeChecked.setHours(activeStartTime.split(":")[0]);
              slotToBeChecked.setMinutes(activeStartTime.split(":")[1]);
              if (
                existingSlotStart < slotToBeChecked &&
                slotToBeChecked < existingSlotEnd &&
                existingSlotStart.getDate() !== existingSlotEnd.getDate()
              ) {
                return i;
              }
            });
            if (hasOverlapSlotFromPrevDay) {
              var nextStartSlotBuffer = addMinutes(
                hasOverlapSlotFromPrevDay.end.split("T")[1],
                parseInt(buffer_minutes)
              );
              activeStartTime = nextStartSlotBuffer;
            }
            // today current time
            let todayTime =
              today.getHours() +
              ":" +
              today.getMinutes() +
              ":" +
              today.getSeconds();

            // get events only for day
            // for loop on the active day and remove all date less than the active date.
            let raw_events_today = [];
            for (var i in raw_events) {
              let event = raw_events[i];
              // start ex. 2020-12-06T10:00:00Z
              let [event_date] = event.start.split("T");
              if (event_date === activeDateStr) {
                // raw_events.splice(i, 1);
                raw_events_today.push(event);
              } else {
                break;
              }
            }
            let conditionEndDate = Date.parse(
              activeDateStrWhileLoopEnd + endTime
            );
            if (endTime === "00:00") {
              conditionEndDate = Date.parse(
                activeDateStrWhileLoopEnd + "23:59"
              );
            }
            var changedNextDate = false;

            // slot inside day. while activeStartTime <= endTime append event to the list
            while (
              Date.parse(activeDateStrWhileLoopStart + activeStartTime) <
              conditionEndDate
            ) {
              if (activeDateStr === nextDateStr) {
                break;
              }

              // create the time slot
              let slot_end_without_buffer = addMinutes(
                activeStartTime,
                calendarSlotDuration
              ); // '18:35'
              let slot_end = addMinutes(
                slot_end_without_buffer,
                buffer_minutes
              );
              let create_slot = true;
              var appointment_buffer = 0;
              var nextStartBuffer = addMinutes(
                slot_end,
                parseInt(appointment_buffer)
              );
              let buffer_end = new Date(activeDateStr + "T" + nextStartBuffer);
              let outOfShiftTime = false;
              // let appBlocked = null;
              //based on appointments on backend,change the slot end and buffer end time
              for (var j in data) {
                let appointment = data[j];
                if (appointment && !appointment.holdWaitingReplacement) {
                  appointment_buffer = appointment?.doctorEventType?.buffer;
                  let app_end_time = appointment.end.split("T")[1];
                  nextStartBuffer = addMinutes(
                    app_end_time.substring(0, 5),
                    parseInt(appointment_buffer)
                  );
                  buffer_end = new Date(activeDateStr + "T" + nextStartBuffer);
                  let app_st = appointment.start;
                  var app_en = appointment.end;
                  let appStart = new Date(app_st);
                  var appEnd = new Date(app_en);
                  let currentSlotEndBuffer = addMinutes(
                    slot_end_without_buffer.substring(0, 5),
                    parseInt(buffer_minutes)
                  );
                  let current_slot = {
                    start: activeDateStr + "T" + activeStartTime,
                    end: activeDateStr + "T" + currentSlotEndBuffer,
                  };

                  let changeDateToNext = false;
                  if (
                    new Date(activeDateStr + "T" + activeStartTime) >
                      new Date(activeDateStr + "T" + currentSlotEndBuffer) &&
                    new Date(nextDateStr + "T" + currentSlotEndBuffer) <
                      refCalendarView.activeEnd
                  ) {
                    changeDateToNext = true;
                  }
                  if (changeDateToNext) {
                    let hasShiftOnNextDate = false;
                    let nextDate = new Date(
                      nextDateStr + "T" + currentSlotEndBuffer
                    );
                    let nextDateShift = businesshours.filter((i) =>
                      i.daysOfWeek.includes(nextDate.getDay())
                    );
                    hasShiftOnNextDate = nextDateShift.find((i) => {
                      let startShift = new Date(nextDate);
                      startShift.setHours(i.startTime.split(":")[0]);
                      startShift.setMinutes(i.startTime.split(":")[1]);

                      let endShift = new Date(nextDate);
                      endShift.setHours(i.endTime.split(":")[0]);
                      endShift.setMinutes(i.endTime.split(":")[1]);

                      if (startShift <= nextDate && nextDate <= endShift) {
                        return i;
                      }
                    });
                    if (hasShiftOnNextDate) {
                      current_slot = {
                        start: activeDateStr + "T" + activeStartTime,
                        end: nextDateStr + "T" + currentSlotEndBuffer,
                      };
                      changedNextDate = true;
                      activeDateStrWhileLoopStart = "02/01/2011 ";
                    } else {
                      break;
                    }
                  }

                  if (current_slot) {
                    let current_slot_start = new Date(current_slot.start);
                    let current_slot_end = new Date(current_slot.end);
                    let stDate = new Date(current_slot.start);
                    let etDate = new Date(app_st);
                    if (
                      stDate.setHours(0, 0, 0, 0) ===
                      etDate.setHours(0, 0, 0, 0)
                    ) {
                      if (
                        // current_slot_start is suggested slot start date , current_slot_end is suggested slot end.
                        // appStart: existing appointment start and appEnd is existing appointment end
                        // buffer_end is the buffer end the current existing appointment buffer end time
                        // in for loop that is being compared against new suggested slot
                        (current_slot_start < appEnd &&
                          current_slot_start >= appStart) ||
                        (current_slot_end < appEnd &&
                          current_slot_end > appStart) ||
                        (current_slot_start <= appStart &&
                          current_slot_end >= appEnd) ||
                        (appointment_buffer &&
                          buffer_end < appEnd &&
                          buffer_end > appStart) ||
                        (current_slot_start <= appStart && buffer_end >= appEnd)
                      ) {
                        create_slot = false;
                        break;
                      }
                    }
                    if (
                      current_slot_end < current_slot_start &&
                      currentSlotEndBuffer !== "00:00"
                    ) {
                      create_slot = false;
                      outOfShiftTime = true;
                      break;
                    }
                  }
                }
              }
              if (!outOfShiftTime) {
                let proposed_start = slot_end;
                if (
                  !create_slot
                  //  &&
                  // compareTimes(proposed_start, nextStartBuffer) === -1
                ) {
                  proposed_start = nextStartBuffer;
                  if (appEnd >= buffer_end) {
                    let new_start =
                      appEnd.getHours() +
                      ":" +
                      String(appEnd.getMinutes()).padStart(2, "0");
                    new_start = addMinutes(
                      new_start,
                      parseInt(appointment_buffer)
                    );
                    proposed_start = new_start;
                  }
                }
                let conditionEndDate2 = Date.parse(
                  activeDateStrWhileLoopEnd + endTime
                );
                if (endTime === "00:00") {
                  conditionEndDate2 = Date.parse(
                    activeDateStrWhileLoopEnd + "23:59"
                  );
                }

                let slotEndTime = slot_end_without_buffer;
                if (slotEndTime === "00:00") {
                  slotEndTime = "23:59";
                }
                let valToPush = {
                  title: "Available",
                  start: activeDateStr + "T" + activeStartTime,
                  end: activeDateStr + "T" + slotEndTime,
                };

                let changeDateToNext = false;
                if (
                  new Date(valToPush.start) > new Date(valToPush.end) &&
                  new Date(nextDateStr + "T" + slotEndTime) <
                    refCalendarView.activeEnd
                ) {
                  changeDateToNext = true;
                }
                if (changeDateToNext) {
                  let hasShiftOnNextDate = false;
                  let nextDate = new Date(nextDateStr + "T" + slotEndTime);
                  let nextDateShift = businesshours.filter((i) =>
                    i.daysOfWeek.includes(nextDate.getDay())
                  );
                  hasShiftOnNextDate = nextDateShift.find((i) => {
                    let startShift = new Date(nextDate);
                    startShift.setHours(i.startTime.split(":")[0]);
                    startShift.setMinutes(i.startTime.split(":")[1]);

                    let endShift = new Date(nextDate);
                    endShift.setHours(i.endTime.split(":")[0]);
                    endShift.setMinutes(i.endTime.split(":")[1]);

                    if (startShift <= nextDate && nextDate <= endShift) {
                      return i;
                    }
                  });
                  if (hasShiftOnNextDate) {
                    valToPush["start"] = activeDateStr + "T" + activeStartTime;
                    valToPush["end"] = nextDateStr + "T" + slotEndTime;
                    activeDateStr = nextDateStr;
                    changedNextDate = true;
                    activeDateStrWhileLoopStart = "02/01/2011 ";
                  } else {
                    break;
                  }
                }

                if (
                  create_slot &&
                  Date.parse(
                    activeDateStrWhileLoopEnd + slot_end_without_buffer
                  ) <= conditionEndDate2 &&
                  !(
                    todayDateStr === activeDateStr &&
                    Date.parse(activeDateStrWhileLoopStart + activeStartTime) <=
                      Date.parse(activeDateStrWhileLoopStart + todayTime)
                  )
                ) {
                  allevents.push(valToPush);
                }

                let proposedSlotStart = new Date(
                  activeDateStr + "T" + proposed_start
                );
                let isProposedLessThanExistingArrTime = allevents.find(
                  (i) => new Date(i.start) > proposedSlotStart
                );
                if (isProposedLessThanExistingArrTime) {
                  activeDateStr = nextDateStr;
                  changedNextDate = true;
                  activeDateStrWhileLoopStart = "02/01/2011 ";
                  break;
                } else {
                  activeDateStrWhileLoopStart = "01/01/2011 ";
                }
                activeStartTime = proposed_start;
              } else {
                activeStartTime = "00:00";
                activeDateStr = nextDateStr;
                changedNextDate = true;
              }
            }
          }
        }
      }
      // if editable app exists then add that slot to available slots so the selected dare/time can show

      let allFilters = allevents.filter((i) => i !== null && i !== undefined);
      if (appFromCal && appFromCal.selectedDateStr && appCreateFromCal) {
        let dateToBlock = appFromCal.selectedDateStr;
        let dd = String(dateToBlock.getDate()).padStart(2, "0");
        let mm = String(dateToBlock.getMonth() + 1).padStart(2, "0");
        let yy = dateToBlock.getFullYear();
        let time =
          "T" +
          String(dateToBlock.getHours()).padStart(2, "0") +
          ":" +
          String(dateToBlock.getMinutes()).padStart(2, "0");
        let dateToBlockStr = yy + "-" + mm + "-" + dd + time;
        let dateToBlockEnd = new Date(dateToBlock);
        dateToBlockEnd.setMinutes(
          dateToBlockEnd.getMinutes() + selectedSession.node.duration
        );

        let ddEnd = String(dateToBlockEnd.getDate()).padStart(2, "0");
        let mmEnd = String(dateToBlockEnd.getMonth() + 1).padStart(2, "0");
        let yyEnd = dateToBlockEnd.getFullYear();
        let timeEnd =
          "T" +
          String(dateToBlockEnd.getHours()).padStart(2, "0") +
          ":" +
          String(dateToBlockEnd.getMinutes()).padStart(2, "0");
        let dateToBlockEndStr = yyEnd + "-" + mmEnd + "-" + ddEnd + timeEnd;

        if (allFilters.find((i) => i.start === dateToBlockStr) || isCustom) {
          // if (true) {
          dateEventInVar({
            start: dateToBlockStr,
            end: dateToBlockEndStr,
          });
        } else {
          alert(
            "The selected date and timeslot on " +
              dateToBlock.toString() +
              " is not available please select other timeslot"
          );
          let dataToSave = appFromCal;
          dataToSave["selectedDateStr"] = null;
          appFromCalVar(dataToSave);
        }
      }

      calendarSlotsVar({
        allFilters: allFilters,
        slotsUntil: end_date,
      });
      setAvailableSlotsArr(allFilters);
    } else if (myAppointments) {
      setAvailableSlotsArr(appData);
    } else {
      setAvailableSlotsArr([]);
    }

    // displayLoaderVar(false);
  };

  // INITIALIZE CALENDAR BASED ON FULL CALENDAR REF AND EVENTS DATA
  useEffect(() => {
    let refCalendarView =
      fullCalendarRef && fullCalendarRef.current
        ? fullCalendarRef?.current?.getApi()?.view
        : null;
    if (refCalendarView) {
      let current_month = refCalendarView.activeEnd.getMonth();
      let calendarEnd = refCalendarView.activeEnd;
      let current_year = refCalendarView.activeStart.getFullYear();
      if (
        calendarActiveMonth !== null &&
        current_month !== calendarActiveMonth
      ) {
        current_month = calendarActiveMonth;
      }
      let dateStart = refCalendarView.activeStart;
      let dateEnd = refCalendarView.activeEnd;
      // if (calendarEnd > dateEnd) {
      //   dateEnd = calendarEnd;
      // }
      // dateEnd.setHours(23);
      dateEnd.setDate(dateEnd.getDate() + 7);
      getAllAvailableSlots(dateStart, dateEnd, data);
      if (calendarActiveMonth === null) {
        calendarActiveMonthVar(current_month);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCalendarRef, data]);

  // SET STATE FROM REACTIVE VARIBALE
  useEffect(() => {
    setData(eventsDataMain);
  }, [eventsDataMain]);

  const getActiveDocShifts = (activeStart, activeEnd, selectedDoctor) => {
    let shiftArr = [];
    for (var d = activeStart; d <= activeEnd; d.setDate(d.getDate() + 1)) {
      let shift_for_day = selectedDoctor?.node?.availability?.edges?.find(
        (i) => i.node.day === d.getDay() + ""
      );
      let dd = String(d.getDate()).padStart(2, "0");
      let mm = String(d.getMonth() + 1).padStart(2, "0");
      let yyyy = d.getFullYear();

      let activeDateStr = yyyy + "-" + mm + "-" + dd;
      let activeDateCustomShift = null;

      if (selectedDoctor?.node?.overrideSchedules) {
        activeDateCustomShift =
          selectedDoctor?.node?.overrideSchedules?.edges.find(
            (i) => i.node.date === activeDateStr
          );
      }

      var SHIFT_OBJ = null;

      if (activeDateCustomShift) {
        SHIFT_OBJ = activeDateCustomShift;
      } else if (shift_for_day) {
        SHIFT_OBJ = shift_for_day;
      }

      if (SHIFT_OBJ?.node?.shifts?.edges) {
        SHIFT_OBJ.node.shifts.edges.map((shift) => {
          let shiftStHrMin = shift.node.startTime.split(":");
          let shiftSt = new Date(d);
          shiftSt.setHours(shiftStHrMin[0]);
          shiftSt.setMinutes(shiftStHrMin[1]);

          let shiftEnHrMin = shift.node.endTime.split(":");
          let shiftEnd = new Date(d);
          shiftEnd.setHours(shiftEnHrMin[0]);
          shiftEnd.setMinutes(shiftEnHrMin[1]);

          let yyyy = shiftSt.getFullYear();

          let dd = String(shiftSt.getDate()).padStart(2, "0");
          let mm = String(shiftSt.getMonth() + 1).padStart(2, "0");
          let hr = String(shiftSt.getHours()).padStart(2, "0");
          let mn = String(shiftSt.getMinutes()).padStart(2, "0");

          let ddEn = String(shiftEnd.getDate()).padStart(2, "0");
          let mmEn = String(shiftEnd.getMonth() + 1).padStart(2, "0");
          let hrEn = String(shiftEnd.getHours()).padStart(2, "0");
          let mnEn = String(shiftEnd.getMinutes()).padStart(2, "0");
          if (hrEn === "00") {
            hrEn = "24";
          }
          shiftArr.push({
            groupId: "shiftGroupId",
            start: yyyy + "-" + mm + "-" + dd + "T" + hr + ":" + mn + ":00",
            end:
              yyyy + "-" + mmEn + "-" + ddEn + "T" + hrEn + ":" + mnEn + ":00",
            display: "background",
          });
        });
      }
    }
    setActiveDocShiftArr(shiftArr);
  };

  // SET STATE FROM REACTIVE VARIBALE
  useEffect(() => {
    let refCalendarView =
      fullCalendarRef && fullCalendarRef.current
        ? fullCalendarRef?.current?.getApi()?.view
        : null;
    if (refCalendarView && selectedDoctor?.node?.availability) {
      getActiveDocShifts(
        refCalendarView.activeStart,
        refCalendarView.activeEnd,
        selectedDoctor
      );
    }

    if (!selectedDoctor) {
      setActiveDocShiftArr([]);
    }
  }, [fullCalendarRef, selectedDoctor]);

  //FUNCTION THAT TRIGGERS ON EVERY VIEW CHANGE
  const handleViewChange = (info) => {
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        let startStr = info.startStr;
        let view = info.view.type;
        let calParamUrl =
          "?calendarInitDate=" + startStr + "&calendarInitView=" + view;
        if (calMounted) {
          // window.history.pushState(null, '',calParamUrl);
        }

        if (selectedDoctor?.node?.availability) {
          getActiveDocShifts(
            info.view.activeStart,
            info.view.activeEnd,
            selectedDoctor
          );
        }
        let current_year = info.view.activeStart.getFullYear();
        let cur_cal_month = info.view.activeStart.getMonth();
        let calendarEnd = info.end;
        if (calendarActiveMonth !== null || myAppointments) {
          let dateEnd = new Date(info.view.activeEnd);
          dateEnd.setHours(dateEnd.getHours() + 5);

          let dateStart = new Date(info.view.activeStart);
          dateStart.setHours(dateStart.getHours() - 5);

          // if (calendarEnd > dateEnd) {
          //   dateEnd = calendarEnd;
          // }
          dateEnd.setDate(dateEnd.getDate() + 7);
          let today = new Date();
          if (!myAppointments && dateStart < today) {
            dateStart = today;
          }
          if (!myAppointments) {
            calendarActiveMonthVar(cur_cal_month);
          }
          let getEventsVar = {
            // doctor_identifier: selectedDoctor.node.identifier,
            start: dateStart,
            end: dateEnd,
          };
          if (
            !myAppointments &&
            (selectedDoctor?.node?.identifier ||
              referralObjectData?.referralObj?.referral?.referredDoctor)
          ) {
            if (selectedDoctor) {
              getEventsVar["doctor_identifier"] =
                selectedDoctor.node.identifier;
            } else if (
              referralObjectData &&
              referralObjectData.referralObj &&
              referralObjectData.referralObj.referral.referredDoctor
            ) {
              getEventsVar["doctor_identifier"] =
                referralObjectData.referralObj.referral.referredDoctor.identifier;
            }
            displayLoaderVar(true);
            GetEvents({ variables: getEventsVar });
          } else if (myAppointments) {
            // if(!eventsLoading){
            // displayLoaderVar(true);
            let dateEnd = new Date(info.view.activeEnd);
            dateEnd.setHours(dateEnd.getHours() + 5);
            let dateStart = new Date(info.view.activeStart);
            dateStart.setHours(dateStart.getHours() - 5);
            getEventsVar = {
              start: dateStart,
              end: dateEnd,
            };

            let patient_Identifier = urlActions(
              window.location.href,
              "get",
              "patient"
            );
            let doctor_Identifier = urlActions(
              window.location.href,
              "get",
              "doctor"
            );
            let status = urlActions(window.location.href, "get", "status");

            getEventsVar["patient_Identifier"] = patient_Identifier;
            getEventsVar["doctor_Identifier"] = doctor_Identifier;
            getEventsVar["status"] = status;
            getEventsVar["active"] = "";
            if (status === "cancelled") {
              getEventsVar["active"] = false;
            } else if (status === "active") {
              getEventsVar["active"] = true;
              getEventsVar["status"] = "";
            }

            // onchange of calendar view/dates this get events is triggered with filters saved in cache along with new calendar view's start and end date

            GetEvents({ variables: getEventsVar });
            // }
          }
        }
        let amt = 0;
        if (info.view.type === "timeGridWeek") {
          amt = 25;
        } else if (info.view.type === "dayGridMonth") {
          amt = 3;
        }
        if (amt !== amountOfEvents) {
          setAmountOfEvents(amt);
        }
      }, 200)
    );
  };
  const renderPopup = () => {
    if (coords?.length > 0) {
      return (
        <div
          className="cancel"
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: "transparent",
            zIndex: 99,
          }}
          onClick={() => {
            setcoords([]);
          }}
        >
          <div
            style={{
              top: coords[1],
              left: coords[0],
            }}
            className="cal-popup-container"
          >
            <div>
              {selectedDateStr?.getHours()}:
              {("0" + selectedDateStr?.getMinutes()).slice(-2)} - Create a:
            </div>
            <div className="cal-link-container">
              <Link
                to={{
                  pathname: "/create/appointment/",
                  search: "?isCustom=true",
                  state: { appCreateFromCal: true },
                }}
                // to={`/create/appointment`}
                // params={{appFromCal:true}}
                onClick={() => {
                  isCustomAppVar(true);
                  let dataToPass = appFromCal ? appFromCal : {};
                  dataToPass["selectedDateStr"] = selectedDateStr;
                  appFromCalVar(dataToPass);
                }}
                className="hover-link"
              >
                Custom Event
              </Link>
              <Link
                to={{
                  pathname: "/create/appointment",
                  state: { appCreateFromCal: true },
                }}
                // to={`/create/appointment`}
                // params={{appFromCal:true}}
                onClick={() => {
                  isCustomAppVar(false);
                  let dataToPass = appFromCal ? appFromCal : {};
                  dataToPass["selectedDateStr"] = selectedDateStr;
                  appFromCalVar(dataToPass);
                }}
                className="hover-link"
              >
                Appointment
              </Link>
            </div>

            {/* {customStart} - {customEnd} */}
          </div>
        </div>
      );
    }
  };

  const getHeight = () => {
    const bodyHeight = document.querySelector(".fc-view-harness");
    if (bodyHeight) {
      setCalendarBodyHeight({ height: bodyHeight.clientHeight + "px" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getHeight();
    }, 200);
    window.addEventListener("resize", getHeight);
    return () => {
      window.removeEventListener("resize", getHeight);
      calendarSlotsVar(null);
    };
  }, []);

  return (
    <>
      {renderPopup()}
      <div className="calendar-container">
        <FullCalendar
          eventTextColor="#000"
          datesSet={handleViewChange}
          titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
          ref={fullCalendarRef}
          plugins={[
            dayGridPlugin,
            timeGridWeek,
            timeGridPlugin,
            interactionPlugin,
          ]}
          // timeZone={'UTC'}
          initialView={
            calendarViewStored !== "" && calendarViewStored !== null
              ? calendarViewStored
              : calendarView
          }
          weekends={true}
          moreLinkText={width <= 500 ? "" : "more"}
          editable={false}
          unselectAuto={false}
          selectable={false}
          selectMirror={false}
          // TODO: change duration to include the current session's duration and buffer time
          slotDuration={"00:" + calendarSlotDuration + ":00"}
          slotMinTime={"00:00:00"}
          slotMaxTime={"25:00:00"}
          eventDisplay="list-item"
          dateClick={(info) => {
            if (
              info.date >= new Date() &&
              myAppointments &&
              info?.view?.type !== "dayGridMonth" &&
              info?.jsEvent?.target?.classList?.contains("fc-bg-event")
            ) {
              const api = fullCalendarRef?.current?.getApi();
              setcoords([info.jsEvent.pageX, info.jsEvent.pageY]);
              setSelectedDateStr(info.date);
            }
          }}
          events={
            availableSlotsArr.length > 0
              ? [...availableSlotsArr, ...activeDocShiftArr]
              : [...activeDocShiftArr]
          }
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
            hour12: false,
          }}
          dayMaxEvents={amountOfEvents}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          eventClick={(info, jsEvent) => {
            if (!info?.jsEvent?.target?.classList?.contains("fc-bg-event")) {
              if (!myAppointments) {
                dateEventInVar({
                  start: info.event.startStr,
                  end: info.event.endStr,
                });
              } else {
                let startStr = info.view.activeStart.toISOString();
                let view = info.view.type;
                let calParamUrl =
                  "calendarInitDate=" + startStr + "&calendarInitView=" + view;
                let newParams = "?";
                if (window.location.search) {
                  newParams = urlActions(
                    window.location.href,
                    "delete",
                    "calendarInitView"
                  );
                  newParams = urlActions(
                    window.location.protocol +
                      "//" +
                      window.location.host +
                      "?" +
                      newParams,
                    "delete",
                    "calendarInitDate"
                  );
                  newParams += "&" + calParamUrl;
                } else {
                  newParams += calParamUrl;
                }

                history.push({
                  pathname: window.location.pathname,
                  search: newParams,
                });
                setTimeout(() => {
                  let publicId = null;
                  let customAppointmentId = null;
                  if (info.event._def.extendedProps.patient) {
                    publicId = info.event._def.publicId;
                  } else {
                    customAppointmentId = info.event._def.publicId;
                  }
                  if (publicId) {
                    let app_url = "/appointment/detail/" + publicId;
                    history.push(app_url);
                  } else {
                    // isCustomAppVar(true)
                    let app_url = "/appointment/detail/" + customAppointmentId;
                    history.push(app_url);
                  }
                }, 100);
              }
            }
          }}
          select={handleChangeView}
          showNonCurrentDates={false}
          eventClassNames={(info) => {
            if (
              selectedDateEvent &&
              selectedDateEvent.dateEvent &&
              selectedDateEvent.dateEvent.start &&
              selectedDateEvent.dateEvent.end
            ) {
              let selectedStart = new Date(
                selectedDateEvent.dateEvent.start
              ).toString();
              let selectedEnd = new Date(
                selectedDateEvent.dateEvent.end
              ).toString();
              let infoStart = new Date(info.event.startStr).toString();
              let infoEnd = new Date(info.event.endStr).toString();
              if (infoStart === selectedStart && infoEnd === selectedEnd) {
                return "selectedSlot";
              }
            }
          }}
          selectAllow={handleSelectAllow}
        />

        {/* QUESTION set condition here */}
        {displayLoader ? (
          <div style={calendarBodyHeight} className="calendar-overlay">
            {/* <span>select patient <br /> select practitioner</span> */}
            <Preloader />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default withRouter(Calendar);
