import React, { useState, useEffect, useRef, useCallback } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { debounce } from "lodash";

import Base from "Views/base.js";
import {
  Pagination,
  Row,
  Col,
  Container,
  Form,
  Table,
  Dropdown,
} from "react-bootstrap";
import Preloader from "Common/Preloder/Preloader.jsx";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  PER_PAGE_COUNT,
  concatAllErrors,
  getDate,
  success_options,
} from "Common/helpers";
import * as Sentry from "@sentry/browser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import useSearchParamsV5 from "Common/hooks/useSearchParams";
import { removeEmpty } from "Common/helpers";

function Inquiries(props) {
  const inputRef = useRef(null);
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [searchTerm, setSearchTerm] = useState("");
  const [activePage, setActivePage] = useState(1);
  const history = useHistory();
  const [searchParams, setSearchParams] = useSearchParamsV5();
  const [count, setCount] = useState(1);
  const [actionChange, setActionChange] = useState("");
  const patientIdentifier = props.match.params.identifier;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0); // Using a timeout to ensure it focuses after render

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  useEffect(() => {
    // this is for page attribute.

    if (searchParams?.page) {
      const page = parseInt(searchParams?.page);

      setActivePage(parseInt(page));

      if (page > 1) {
        setCount(PER_PAGE_COUNT * (page - 1) + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ("search" in searchParams) {
      setSearchTerm(searchParams.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const INQUIRY_REQUESTS = gql`
    query (
      $search: String
      $doctor_Identifier: String
      $offset: Int
      $start: Date
      $end: Date
    ) {
      patientInquiries(
        searchText_Icontains: $search
        doctor_Identifier: $doctor_Identifier
        offset: $offset
        start: $start
        end: $end
      ) {
        totalCount

        edges {
          node {
            latestInquiry {
              pk
              id
              created
              status
              patientNotes
              read
              statusDisplay
              doctor {
                id
                firstName
                lastName
              }
            }

            id
            firstName
            lastName
            identifier
            phone
            email
          }
        }
      }
    }
  `;

  const CHANGE_REQUEST_STATUS = gql`
    mutation changePresRequestStatus(
      $id: Int!
      $status: String
      $notes: String
      $read: Boolean
    ) {
      changePresRequestStatus(
        id: $id
        status: $status
        notes: $notes
        read: $read
      ) {
        obj {
          pk
          status
        }
      }
    }
  `;

  const [changeStatus, { loading: changeStatusLoader }] = useMutation(
    CHANGE_REQUEST_STATUS,
    {
      onCompleted({ changePresRequestStatus }) {
        if (changePresRequestStatus) {
          setActionChange(changePresRequestStatus?.obj?.status);
          openSnackbarSuccess("Successfully changed status.", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [getInquiries, { data, loading }] = useLazyQuery(INQUIRY_REQUESTS, {
    fetchPolicy: "network-only",
    pollInterval: 5 * 60 * 1000, // 5mins
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    let payload = {
      offset: 0,
      ...searchParams,
    };

    if (searchParams?.page) {
      payload.offset = PER_PAGE_COUNT * parseInt(searchParams.page - 1);
    }

    if (patientIdentifier) {
      payload.patient_Identifier = patientIdentifier;
    }

    payload = removeEmpty(payload);
    getInquiries({ variables: payload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams?.search,
    searchParams?.start,
    searchParams?.end,
    getInquiries,
    actionChange,
    patientIdentifier,
  ]);

  const handleDateChange = (type, date) => {
    if (type === "start") {
      setSearchParams({
        ...searchParams,
        start: date ? moment(date).format("YYYY-MM-DD") : "",
        page: 1,
      });
    } else {
      setSearchParams({
        ...searchParams,
        end: date ? moment(date).format("YYYY-MM-DD") : "",
        page: 1,
      });
    }
  };

  const REQUEST_DOCTOR = gql`
    query {
      doctors(publicPractitioner: true) {
        edges {
          node {
            firstName
            lastName
            id
            email
            identifier
            doctorId
          }
        }
      }
    }
  `;

  const { data: doctors = [] } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (err) => {
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const handleDoctor = (event) => {
    setSearchParams({
      ...searchParams,
      doctor_Identifier: event.target.value,
      page: 1,
    });
  };

  const handleStatusChange = (event) => {
    setSearchParams({
      ...searchParams,
      status: event.target.value,
      page: 1,
    });
  };

  const total_pages =
    data && data.patients && data.patients.totalCount
      ? Math.ceil(data.patients.totalCount / PER_PAGE_COUNT)
      : 1;

  const patients =
    data && data.patientInquiries ? data.patientInquiries.edges : [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearch = useCallback(
    debounce((value) => {
      setSearchParams({ ...searchParams, search: value, page: 1 });
    }, 500),
    [] // Dependency array can be left empty, as it doesn't depend on any external state
  );

  const handleChange = (event) => {
    const value = event.target.value;
    handleDebouncedSearch(value);
    setSearchTerm(event.target.value);
  };

  const createPrescription = (node) => {
    history.push({
      pathname: `/prescription/print/${node.id}`,
    });
  };

  const renderPages = () => {
    if (total_pages > 1) {
      let currentpage = [];
      if (activePage == 1) {
        currentpage = [
          <Pagination.Item active={true} onClick={() => handlePageChange(1)}>
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      } else if (activePage == total_pages) {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(total_pages)}
          >
            {activePage}
          </Pagination.Item>,
        ];
      } else {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(activePage)}
          >
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      }

      let itemsStart = [];
      if (activePage == 1 || activePage == 2) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
        ];
      } else if (activePage == 3) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
        ];
      } else {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
          <Pagination.Ellipsis disabled={true} />,
        ];
      }

      let itemsEnd = [];
      if (activePage == total_pages || activePage == total_pages - 1) {
        itemsEnd = [
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else if (activePage == total_pages - 2) {
        itemsEnd = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else {
        itemsEnd = [
          <Pagination.Ellipsis disabled={true} />,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      }

      let allPages = [...itemsStart, ...currentpage, ...itemsEnd];
      return allPages;
    }
  };

  const handlePageChange = (number) => {
    setSearchParams({ ...searchParams, page: number });
    setActivePage(number);

    if (number === 1) {
      setCount(1);
    } else {
      setCount(PER_PAGE_COUNT * (number - 1) + 1);
    }
  };

  const handleChangeStatus = useCallback(
    (item, status) => {
      let message = `Are you sure you want to mark this ${status}?`;

      if (status === "cancelled") {
        message = `Are you sure you want to mark this CANCEL? By doing so the patient will receive message to notify them.`;
      }

      if (window.confirm(message)) {
        const payload = {
          id: item?.pk,
          status: status,
        };

        changeStatus({ variables: payload });
      }
    },
    [changeStatus]
  );

  const handleReadStatus = useCallback(
    (item, status) => {
      let message = "";
      if (item?.read) {
        message = `Are you sure you want to mark this unread?`;
      } else {
        message = `Are you sure you want to mark this read?`;
      }

      if (window.confirm(message)) {
        const payload = {
          id: item?.pk,
          read: !item?.read,
        };

        changeStatus({ variables: payload });
      }
    },
    [changeStatus]
  );

  return (
    <Base title={"Patient inquiries"} showHeader={true}>
      <Container fluid>
        <Row className="mb-2">
          <Col>
            <Form.Group as={Row}>
              <Form.Label column md={3}>
                Search
                <Form.Control
                  ref={inputRef}
                  autoComplete="off"
                  type="text"
                  placeholder="File #/CivilID/Phone"
                  value={searchTerm}
                  onChange={handleChange}
                  column
                  md={8}
                />
              </Form.Label>
              <Form.Label column md={3}>
                Practitioner
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="doctor"
                  value={searchParams?.doctor_Identifier || ""}
                  onChange={handleDoctor}
                  column
                  md={8}
                >
                  <option value="">Select Practitioner</option>
                  {doctors &&
                    doctors?.doctors?.edges?.map((doctor) => {
                      return (
                        <option
                          value={doctor.node.identifier}
                          key={doctor.node.id}
                        >
                          {doctor.node.firstName} {doctor.node.lastName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Label>
              <Form.Label column md={3}>
                Status
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="status"
                  value={searchParams?.status || ""}
                  onChange={handleStatusChange}
                  column
                  md={8}
                >
                  <option value="">Select Status</option>
                  <option value="pending">Pending</option>
                  {/* <option value="inprogress">In progress</option> */}
                  <option value="completed">Completed</option>
                  <option value="cancelled">Cancelled</option>
                </Form.Control>
              </Form.Label>
              <Form.Label column md={3}>
                Start
                <DatePicker
                  selected={
                    searchParams?.start ? new Date(searchParams.start) : ""
                  }
                  onChange={(date) => handleDateChange("start", date)}
                  name="start"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                />
              </Form.Label>
              <Form.Label column md={3}>
                End
                <DatePicker
                  selected={searchParams?.end ? new Date(searchParams.end) : ""}
                  onChange={(date) => handleDateChange("end", date)}
                  name="end"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                />
              </Form.Label>

              <Form.Label column md={3}>
                Total number
                <h4>
                  <strong>{data?.patientInquiries?.totalCount || 0}</strong>
                </h4>
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row className="patient_table_row request-table">
          <Table bordered responsive>
            <thead>
              <tr>
                <th className="align-middle">No.</th>
                <th className="align-middle">File number</th>
                <th className="align-middle">Patient Name</th>
                <th className="align-middle">Phone Number</th>
                <th className="align-middle">Status</th>
                <th className="align-middle">Date requested</th>
                <th className="align-middle">Notes</th>
                <th className="align-middle">Actions</th>
              </tr>
            </thead>
            {loading ? (
              <Preloader />
            ) : patients.length > 0 ? (
              <tbody>
                {patients.map((item, index) => {
                  const itemNode = item?.node;
                  const inquiry = itemNode?.latestInquiry;
                  const patient = itemNode;
                  // const doctor = itemNode?.doctor;
                  const status = itemNode.status;

                  const patientName = `${patient.firstName} ${patient.lastName}`;
                  // const doctorName = `${doctor.firstName} ${doctor.lastName}`;

                  const created = inquiry?.created
                    ? getDate(new Date(inquiry?.created))
                    : "-";

                  return (
                    <tr
                      key={index}
                      className={status === "PENDING" ? "bg-grey" : ""}
                    >
                      <td className="align-middle">
                        {index + count}{" "}
                        {!inquiry?.read && (
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        )}
                      </td>
                      <td className="align-middle">
                        <Link
                          key={index}
                          to={"/patient/record/" + patient.identifier}
                          target={"_blank"}
                        >
                          {patient.identifier}
                        </Link>
                      </td>
                      <td className="align-middle">
                        <Link
                          key={index}
                          to={"/patient/record/" + patient.identifier}
                          target={"_blank"}
                        >
                          {patientName}
                        </Link>
                      </td>
                      <td className="align-middle">{patient.phone || "-"}</td>
                      <td className="align-middle">{inquiry?.statusDisplay}</td>

                      <td className="align-middle">{created}</td>
                      <td className="align-middle">
                        <div className="ellipsis">
                          {inquiry?.patientNotes || "-"}
                        </div>
                      </td>

                      <td className="align-middle">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                          >
                            Actions
                          </Dropdown.Toggle>

                          {/* <Dropdown.Menu>
                            {!itemNode?.prescription &&
                              itemNode?.status === "PENDING" && (
                                <>
                                  <Dropdown.Item
                                    onClick={() => createPrescription(itemNode)}
                                  >
                                    Create
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href={`/patient/prescription/history/${patient.identifier}/${itemNode.id}`}
                                  >
                                    Create from existing
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleChangeStatus(itemNode, "cancelled")
                                    }
                                  >
                                    Cancel request
                                  </Dropdown.Item>
                                </>
                              )}

                            <Dropdown.Item
                              onClick={() =>
                                handleReadStatus(itemNode, "completed")
                              }
                            >
                              {itemNode?.read ? "Mark unread" : "Mark read"}
                            </Dropdown.Item>

                            {itemNode?.prescription?.id ? (
                              <Dropdown.Item
                                href={`/prescription/details/${itemNode.prescription.id}`}
                                target={"_blank"}
                              >
                                Open prescription
                              </Dropdown.Item>
                            ) : null}

                         
                          </Dropdown.Menu> */}
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="align-middle text-center" colSpan={8}>
                    Not Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </Row>

        {data?.patientInquiries?.totalCount > PER_PAGE_COUNT ? (
          <div className="paginationContainer pt-1">
            <Pagination>{renderPages()}</Pagination>
          </div>
        ) : null}
      </Container>
    </Base>
  );
}
export default withRouter(Inquiries);
