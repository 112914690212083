import React from "react";
import Navigation from "../component/Navigation/Navigation";
// import { gql, useQuery } from '@apollo/client';
import { Row, Col, Container, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

const Base = (props) => {
  return (
    <Container fluid>
      <Row className={"h100vh d-print-none"}>
        <Col xs={12} sm={12} md={3} xl={2} className={"navigation_conainer"}>
          <Navigation />
        </Col>
        <Col xs={12} sm={12} md={9} xl={10} className="right_container">
          {props.showHeader ? (
            <Row className="page_header">
              <Col xs={12} md={{ span: 4, order: "first" }}>
                {!props.hideBack && props.history.length > 1 ? (
                  <Button
                    variant="link"
                    className="back"
                    onClick={() => props.history.goBack()}
                  >
                    <i className="fa fa-long-arrow-left" aria-hidden="true"></i>{" "}
                    Back
                  </Button>
                ) : null}
              </Col>
              <Col xs={12} md={4}>
                <h3 className="text-center m-0">{props.title}</h3>
              </Col>
              <Col
                xs={12}
                md={{ span: 4, order: "last" }}
                className="d-flex justify-content-end"
              >
                {props.rightChild ? props.rightChild : null}
              </Col>
            </Row>
          ) : null}
          <Container fluid className="baseContainer">
            {props.children}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Base);
