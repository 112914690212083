import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Table,
  Pagination,
} from "react-bootstrap";
import Base from "./base.js";
import { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { gql, useQuery, useLazyQuery, useReactiveVar } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getDate,
  PER_PAGE_COUNT,
  urlActions,
  getValidDateStringForGraphQL,
  concatAllErrors,
} from "../Common/helpers";
import SelectPatientModal from "./SearchForPatient/selectPatientModal";
import { urlBackend } from "../index";
import Preloader from "../Common/Preloder/Preloader";
import { paymentsFilterVar } from "../cache/cache.js";
import queryString from "query-string";
import * as Sentry from "@sentry/browser";

export const REQUEST_DOCTOR = gql`
  query {
    doctors(user_IsActive: true) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          doctorId
        }
      }
    }
  }
`;

export default function Payments(props) {
  const [activePage, setActivePage] = useState(1);
  const searchPatientRef = useRef();
  const formikRef = useRef();
  const [openSnackbar] = useSnackbar(error_options);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const [showExport, setShowExport] = useState(true);
  const [exportLink, setExportLink] = useState(
    urlBackend +
      "admin/payment/paymentmodel/export/?paid__exact=1&active_payment=active"
  );
  const [total, setTotal] = useState(null);
  const { history, location } = props;
  const paymetnsFilter = useReactiveVar(paymentsFilterVar);

  useEffect(() => {
    let page = urlActions(window.location.href, "get", "page");
    if (page) {
      setActivePage(parseInt(page));
    }

    return () => {
      if (props?.history?.location?.pathname?.indexOf("/payments") < 0) {
        paymentsFilterVar({});
        setStartDate("");
        setEndDate("");
      }
    };
  }, []);

  const REQUEST_PATIENT = gql`
    query GetPatients($search: String) {
      patients(search: $search) {
        edges {
          node {
            firstName
            lastName
            id
            email
            identifier
            phone
          }
        }
      }
    }
  `;

  const [getPatients, { data: patientsData = null }] = useLazyQuery(
    REQUEST_PATIENT,
    {
      fetchPolicy: "network-only",
      onError: (err) => {
        Sentry.setContext("error", err?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: err });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: err?.networkError?.statusCode,
        });
        Sentry.captureException("getPatients error " + err);

        let errorMsg = concatAllErrors(err?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const REQUEST_PAYMENTS_FILTER = gql`
query($start:Date, $end:Date, $doctor:String, $patient:String $status: String)  {
  payments(
    start: $start, end: $end, 
    doctor_Identifier: $doctor, 
    patient_Identifier: $patient,
    status: $status,
    first:${PER_PAGE_COUNT}, 
    offset:${PER_PAGE_COUNT * (activePage - 1)}
    )
  {
    totalCount
    totalAmount
    edges{
      node{
        created
        reason
        actualDate
        datePaid
        sources{
					edges{
						node{
							transactions {
								edges {
									node {
										created
										modified
										status
									}
								}
							}
						}
					}
				}
        doctor{
          firstName
          lastName
        }
        patient{
          identifier
          displayGender
          firstName
          lastName
          phone
        }
        id
        displayPaymentType
        displaySourceTypes
        amount
        currency
        notes
        appointment{
          originalPrice
          discount
          id
          start
          status
          doctor{
            firstName
            lastName
          }
          eventType{
            title
          }
        }
      }
    }
  }
}
`;

  const [getPaymentsFilter, { data: paymentsObjFilter = null, loading }] =
    useLazyQuery(REQUEST_PAYMENTS_FILTER, {
      fetchPolicy: "network-only",
      onCompleted(result) {
        const { payments } = result;
        if (payments) {
          setTotal(payments.totalAmount);
        }
      },
    });

  useEffect(() => {
    let query_string_obj = process_query_string();
    let new_variables = {};
    if (
      "doctorIdentifier" in query_string_obj &&
      query_string_obj.doctorIdentifier
    ) {
      formikRef.current.setFieldValue(
        "doctor",
        query_string_obj.doctorIdentifier
      );
      new_variables["doctor"] = query_string_obj.doctorIdentifier;
    }
    if ("patient" in query_string_obj && query_string_obj.patient) {
      getPatients({
        variables: { search: `${query_string_obj.patient}` },
      });
      new_variables["patient"] = query_string_obj.patient;
    }
    if ("startDate" in query_string_obj && query_string_obj.startDate) {
      let startDateObj = new Date(query_string_obj.startDate);
      setStartDate(startDateObj);
      new_variables["start"] = getValidDateStringForGraphQL(
        new Date(query_string_obj.startDate)
      );
    }
    if ("endDate" in query_string_obj && query_string_obj.endDate) {
      let endDateObj = new Date(query_string_obj.endDate);
      setEndDate(endDateObj);
      new_variables["end"] = getValidDateStringForGraphQL(
        new Date(query_string_obj.endDate)
      );
    }
    if ("status" in query_string_obj && query_string_obj.status) {
      formikRef.current.setFieldValue("status", query_string_obj.status);
      new_variables["status"] = query_string_obj.status;
    }
    if (Object.keys(new_variables).length > 0) {
      getPaymentsFilter({
        variables: new_variables,
      });
    } else {
      getPaymentsFilter();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (patientsData && patientsData.patients.edges.length > 0) {
      let first_result = patientsData.patients.edges[0];
      let patient = first_result.node;
      formikRef.current.setFieldValue(
        "patient",
        `${patient.firstName} ${patient.lastName}`
      );
      setSelectedPatient(patient);
    }
  }, [patientsData]);

  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("doctorsList error " + err);

      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const doctors =
    doctorsList && doctorsList.doctors ? doctorsList.doctors.edges : [];

  const onSelectPatient = (patientData) => {
    formikRef.current.setFieldValue(
      "patient",
      `${patientData.firstName} ${patientData.lastName}`
    );
    setSelectedPatient(patientData);
  };

  const onSubmit = (values) => {
    // since we have date variable in the filter query we need to pass a valid date
    //  hence creating dummy dates that will cover all dates incase date filter is not seleted
    // let defaultStart= new Date("2002/02/02");
    // let defaultEnd= new Date("2052/02/02");
    let startDateStr = "";
    let endDateStr = "";

    if (startDate) {
      let startDateObj = new Date(startDate);
      startDateObj.setHours(3);
      startDateObj.setMinutes(55);
      startDateStr = new Date(startDateObj).toISOString();
    }
    if (endDate) {
      let endDateObj = new Date(endDate);
      //endDateObj.setDate(endDateObj.getDate()+1);
      endDateObj.setHours(23);
      endDateObj.setMinutes(55);
      endDateStr = endDateObj.toISOString();
    }

    let variables = {};
    let doctorId = "";
    let patientId = "";
    let export_link =
      urlBackend +
      "admin/payment/paymentmodel/export/?paid__exact=1&active_payment=active";
    if (values.doctor) {
      variables["doctor"] = values.doctor;
      let doctorIdObj = doctors.find(
        (i) => i.node.identifier === values.doctor
      );
      if (doctorIdObj) {
        doctorId = doctorIdObj.node.doctorId;
        export_link = export_link + "&doctor__id__exact=" + doctorId;
      }
    }
    if (values?.status) {
      variables["status"] = values.status;
      export_link =
        export_link + "&appointment__status__exact=" + values.status;
    }
    if (selectedPatient) {
      variables["patient"] = selectedPatient ? selectedPatient.identifier : "";
      patientId = selectedPatient.patientId;
      export_link = export_link + "&patient__id__exact=" + patientId;
    }
    if (startDateStr) {
      startDateStr = startDateStr.substring(0, 10);
      variables["start"] = startDateStr;
      export_link = export_link + "&actual_date__range__gte=" + startDateStr;
    }
    if (endDateStr) {
      endDateStr = endDateStr.substring(0, 10);
      variables["end"] = endDateStr;
      export_link = export_link + "&actual_date__range__lte=" + endDateStr;
    }
    setExportLink(export_link);
    setShowExport(true);

    if (variables) {
      // if not empty assign it to the global varaible
      let new_data = {};
      if ("doctor" in variables) {
        new_data["doctorIdentifier"] = variables.doctor;
      }
      if ("start" in variables) {
        new_data["startDate"] = variables.start;
      }
      if ("end" in variables) {
        new_data["endDate"] = variables.end;
      }
      if ("patient" in variables) {
        new_data["patient"] = variables.patient;
      }
      if ("status" in variables) {
        new_data["status"] = variables.status;
      }

      paymentsFilterVar(new_data);

      let query_string = process_query_string(new_data);
      const stringified = queryString.stringify(query_string);
      window.history.pushState(
        null,
        null,
        location.pathname + "?" + stringified
      );
    }
    getPaymentsFilter({
      variables: variables,
    });
  };

  const initialValues = {
    doctor: "",
    patient: "",
    paidby: "",
  };

  const goToDetailPage = () => {
    if (
      selectedPatient ||
      formikRef.current.values.doctor ||
      startDate ||
      endDate
    ) {
      paymentsFilterVar({
        patient: selectedPatient?.identifier,
        doctorIdentifier: formikRef.current.values.doctor,
        startDate: startDate,
        endDate: endDate,
      });
    } else {
      paymentsFilterVar(null);
    }
  };

  const renderList = (paymentListObj) => {
    let payment_list =
      paymentListObj && paymentListObj.payments
        ? paymentListObj.payments.edges
        : [];
    if (payment_list.length > 0) {
      return payment_list.map((itemNode, index) => {
        let item = itemNode.node;
        let date = item.datePaid ? new Date(item.datePaid) : null;
        let app_date = item.appointment ? item.appointment.start : null;
        if (app_date) {
          app_date = new Date(app_date);
          app_date = getDate(app_date);
        }
        return (
          <tr
            key={index}
            onClick={() => {
              goToDetailPage();
              history.push({
                pathname: `/payments/detail/${item.patient.identifier}`,
                state: {
                  item,
                },
              });
            }}
            style={{ cursor: "pointer" }}
          >
            <td>{date ? getDate(date) : "-"}</td>
            <td className="textGrey">
              {item.patient ? item.patient.identifier : null}
            </td>
            <td>
              {item.patient ? item.patient.firstName : null}{" "}
              {item.patient ? item.patient.lastName : null}
            </td>
            <td className="textGrey">
              {item.patient ? item.patient.displayGender : null}
            </td>
            <td className="textGrey">{item.reason}</td>
            <td className="textGrey">{item.displaySourceTypes}</td>
            <td className="textGrey">
              {" "}
              {item?.appointment?.discount ? item?.appointment?.discount : "-"}
            </td>

            <td className="textGrey">
              {item.amount} {item.currency}
            </td>
            <td className="textGrey">{app_date}</td>
            <td className="textGrey">{item.notes}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>No payments made</td>
        </tr>
      );
    }
  };

  const resetFilter = () => {
    setShowExport(false);
    if (formikRef && formikRef.current) {
      formikRef.current.handleReset();
    }
    setSelectedPatient(null);
    setStartDate("");
    setEndDate("");
    paymentsFilterVar({});
    history.push(location.pathname);
  };
  const renderPages = () => {
    let total_pages = paymentsObjFilter?.payments?.totalCount
      ? Math.ceil(paymentsObjFilter?.payments?.totalCount / PER_PAGE_COUNT)
      : 1;

    if (total_pages > 1) {
      let currentpage = [];
      if (activePage == 1) {
        currentpage = [
          <Pagination.Item active={true} onClick={() => handlePageChange(1)}>
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      } else if (activePage == total_pages) {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(total_pages)}
          >
            {activePage}
          </Pagination.Item>,
        ];
      } else {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(activePage)}
          >
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      }

      let itemsStart = [];
      if (activePage == 1 || activePage == 2) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
        ];
      } else if (activePage == 3) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
        ];
      } else {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
          <Pagination.Ellipsis disabled={true} />,
        ];
      }

      let itemsEnd = [];
      if (activePage == total_pages || activePage == total_pages - 1) {
        itemsEnd = [
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else if (activePage == total_pages - 2) {
        itemsEnd = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else {
        itemsEnd = [
          <Pagination.Ellipsis disabled={true} />,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      }

      let allPages = [...itemsStart, ...currentpage, ...itemsEnd];
      return allPages;
    }
  };

  const handlePageChange = (number) => {
    setActivePage(number);
    let query_string = process_query_string();
    query_string["page"] = number;
    const stringified = queryString.stringify(query_string);
    window.history.pushState(null, "", "?" + stringified);
  };

  const process_query_string = (data = {}) => {
    let query_string_obj = queryString.parse(window.location.search);

    // current filter key, values
    if (Object.keys(data).length <= 0) {
      data = paymetnsFilter;
    }

    for (const item in data) {
      query_string_obj[item] = data[item];
    }
    return query_string_obj;
  };

  return (
    <Base title={"Payments"} showHeader={true}>
      <Row className="payment-section">
        <Col md={12} lg={10} className="mr-auto">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            innerRef={formikRef}
          >
            {({ handleSubmit, handleChange, handleBlur, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={4}
                        className="text-right pr-0"
                      >
                        From
                      </Form.Label>
                      <Col sm={12} md={9} lg={8}>
                        <DatePicker
                          autoComplete="off"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={4}
                        className="text-right pr-0"
                      >
                        To
                      </Form.Label>
                      <Col sm={12} md={9} lg={8}>
                        <DatePicker
                          autoComplete="off"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={4}
                        className="text-right pr-0"
                      >
                        Practitioner
                      </Form.Label>
                      <Col sm={12} md={9} lg={8}>
                        <Form.Control
                          autoComplete="off"
                          onBlur={handleBlur}
                          as="select"
                          name="doctor"
                          value={values.doctor}
                          onChange={handleChange}
                        >
                          <option value="">Select Practitioner</option>
                          {doctors.map((doctor) => {
                            return (
                              <option
                                value={doctor.node.identifier}
                                key={doctor.node.id}
                              >
                                {doctor.node.firstName} {doctor.node.lastName}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={4}
                        className="text-right pr-0"
                      >
                        Patient
                      </Form.Label>
                      <Col
                        sm={12}
                        md={9}
                        lg={8}
                        className="payment-section__patient-search"
                        ref={searchPatientRef}
                      >
                        <Form.Control
                          autoComplete="off"
                          placeholder="Select Patient"
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="patient"
                          value={values.patient}
                          className="mr-sm-2 pr-0 float-left pr-5"
                        />
                        <span
                          onClick={() => setShowPatientSearch(true)}
                          className="search-icon"
                        >
                          <i className="fa fa-search"></i>
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={4}
                        className="text-right pr-0"
                      >
                        Appointment status
                      </Form.Label>
                      <Col sm={12} md={9} lg={8}>
                        <Form.Control
                          autoComplete="off"
                          onBlur={handleBlur}
                          as="select"
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                        >
                          <option value="">Select Practitioner</option>
                          <option value="confirmed">Confirmed</option>
                          <option value="no_show">No show</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex justify-content-end d-row pt-3 submitPayment">
                  {showExport && (
                    <Button
                      type="button"
                      variant="link"
                      onClick={() => window.open(exportLink, "_blank")}
                    >
                      {" "}
                      Export{" "}
                    </Button>
                  )}
                  <Button
                    type="button"
                    variant="link"
                    className="mr-3"
                    onClick={resetFilter}
                  >
                    {" "}
                    Reset{" "}
                  </Button>
                  <Button type="submit"> Submit </Button>
                </div>
                {total ? (
                  <h3 style={{ marginRight: "50px", textAlign: "right" }}>
                    Total: {total} KWD
                  </h3>
                ) : (
                  <div />
                )}
              </Form>
            )}
          </Formik>
        </Col>
        <Col md={12} className="mb-4 overflow-scroll">
          {loading ? (
            <Preloader />
          ) : (
            <Card className="payment-card">
              <Card.Body>
                <Table responsive className="table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">File Number</th>
                      <th scope="col">Patients Name</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Reason</th>
                      <th scope="col">Payment Type</th>
                      <th scope="col">Appointment Discount</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Appointment Date</th>
                      <th scope="col">Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentsObjFilter ? renderList(paymentsObjFilter) : null}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
          <div className="text-center">
            {paymentsObjFilter?.payments?.totalCount > PER_PAGE_COUNT ? (
              <div className="paginationContainer pt-5">
                <Pagination>{renderPages()}</Pagination>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </Base>
  );
}
